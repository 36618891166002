import variants from "@/src/paywall/variants.json";
import { PaywallContext } from "contexts/PaywallContext";
import { useContext } from "react";

const usePayWall = () => {
  const context = useContext(PaywallContext);

  const setPaywall = ({
    isOpen,
    isClosable,
    variant = "default",
    title = "This recipe",
  }) => {
    context.setIsOpen(isOpen);
    context.setIsClosable(isClosable);
    context.setVariant(variant);
    context.setTitle(title);
  };

  const closePaywall = () => {
    context.setIsOpen(false);
  };

  return { setPaywall, closePaywall, variants };
};

export default usePayWall;
