import clsx from "clsx";
import { Minus, Plus, Time } from "components/Icons";
import { motion } from "framer-motion";
import { formatTimeLeft } from "lib/helpers";
import { observer } from "mobx-react";
import Timer from "models/Timer";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useInterval } from "react-use";

const TimerDisplay = observer(({ timer, isLarge }) => {
  const [showFinishedDisplay, setShowFinishedDisplay] = useState(false);

  const ref = useRef(null);

  const handleAdjustment = (minutes) => {
    // if no time left, reset and start again
    if (timer.isFinished) timer.reset();

    // Reset the finished display
    setShowFinishedDisplay(false);

    // Adjust the minutes
    timer.adjustMinutes(minutes);
  };

  const handleActivateClick = () => {
    setShowFinishedDisplay(true);
  };

  const iconSize = isLarge ? 18 : 16;

  const className = clsx("TimerDisplay", {
    "TimerDisplay--isFinished": timer.isFinished,
    "TimerDisplay--isLarge": isLarge,
  });

  return (
    <motion.div
      className={className}
      style={{
        color: timer.timeLeft <= 60 ? "#ffffff" : "#000000",
        backgroundColor: timer.timeLeft <= 60 ? "#FF0000" : "#ffffff",
        borderColor: timer.timeLeft <= 60 ? "#FF0000" : "#000000",
      }}
      ref={ref}
    >
      {(!timer.isFinished || showFinishedDisplay) && (
        <div className="TimerDisplay__display">
          <button
            className="TimerDisplay__action TimerDisplay__action--add"
            type="button"
            onClick={() => handleAdjustment(1)}
          >
            <Plus size={iconSize} />
          </button>

          <div className="TimerDisplay__time">
            {formatTimeLeft(timer.timeLeft)}
          </div>

          <button
            className="TimerDisplay__action TimerDisplay__action--minus"
            type="button"
            onClick={() => handleAdjustment(-1)}
          >
            <Minus size={iconSize} />
          </button>
        </div>
      )}

      {timer.isFinished && !showFinishedDisplay && (
        <Finished handleActivateClick={handleActivateClick} />
      )}
    </motion.div>
  );
});

const Finished = ({ handleActivateClick }) => {
  const [isIconAnimating, setIsIconAnimating] = useState(false);

  const applyAnimation = () => {
    setIsIconAnimating(true);
    setTimeout(() => {
      setIsIconAnimating(false);
    }, 2250);
  };

  useInterval(applyAnimation, 10000);

  useEffect(() => {
    applyAnimation();
  }, []);

  const iconClassName = clsx("TimerDisplay__finishedIcon", {
    "TimerDisplay__finishedIcon--isAnimating": isIconAnimating,
  });

  return (
    <button
      className="TimerDisplay__finished"
      onClick={handleActivateClick}
      type="button"
    >
      <div className={iconClassName}>
        <Time />
      </div>
    </button>
  );
};

TimerDisplay.defaultProps = {
  isLarge: false,
  timer: PropTypes.instanceOf(Timer),
};

export default TimerDisplay;
