import * as React from "react";

type GeoPreferenceContextProviderType = {
  children: React.ReactNode;
};

// Create a new context called GeoPreference
export const GeoPreferenceContext = React.createContext({
  measurement: "metric",
  setMeasurement: (measurement: string) => {
    console.log("Not yet available");
  },
});

export const GeoPreferenceContextProvider: React.FunctionComponent<
  GeoPreferenceContextProviderType
> = ({ children }) => {
  const [measurement, setMeasurement] = React.useState("metric");
  const [checkedGeo, setCheckedGeo] = React.useState(false);

  // When measurement changes, set it in local storage
  React.useEffect(() => {
    if (checkedGeo) {
      localStorage.setItem("mob_measurement", measurement);
    }
  }, [measurement, checkedGeo]);

  // Look to see if mob_measurement local storage exists and set it to that
  React.useEffect(() => {
    const localMeasurement = localStorage.getItem("mob_measurement");
    if (localMeasurement) {
      setCheckedGeo(true);
      setMeasurement(localMeasurement);
    } else {
      const fetchMeasurement = async () => {
        console.log("Fetching geo data");
        try {
          const response = await fetch("/api/geo");
          const data = await response.json();
          if (data && data.unit) {
            setCheckedGeo(true);
            setMeasurement(data.unit);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching measurement:", error);
        }
      };
      fetchMeasurement();
    }
  }, []);

  return (
    <GeoPreferenceContext.Provider value={{ measurement, setMeasurement }}>
      {children}
    </GeoPreferenceContext.Provider>
  );
};

export default GeoPreferenceContextProvider;
