"use client";

import { ReactNode, createContext, useContext, useState } from "react";

type MenuContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  toggle: () => void;
};

// Provide initial context value
const initialContextValue: MenuContextType = {
  isOpen: false,
  setIsOpen: () => {},
  toggle: () => {},
};

// Creating new context
export const MenuContext = createContext<MenuContextType>(initialContextValue);

type MenuProviderProps = {
  children: ReactNode;
};

// Provider component
export const MenuContextProvider = ({ children }: MenuProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        setIsOpen,
        toggle,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = (): MenuContextType => {
  const context = useContext(MenuContext);

  if (context === undefined) {
    throw new Error("useMenuContext must be used within a MenuProvider");
  }

  return context;
};
