import clsx from "clsx";

import { ArrowPathIcon } from "@heroicons/react/24/outline";

const Spinner = ({ size = "md" }: { size?: "xs" | "sm" | "md" | "lg" }) => {
  return (
    <div>
      <ArrowPathIcon
        strokeWidth={1.5}
        className={clsx("animate-spin", {
          "h-3 w-3": size === "xs",
          "h-4 w-4": size === "sm",
          "h-5 w-5": size === "md",
          "h-6 w-6": size === "lg",
        })}
      />
    </div>
  );
};

export default Spinner;
