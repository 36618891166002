import EntryThumbnailCompact from "@/components/EntryThumbnailCompact";
import QuerySuggestions from "@/components/QuerySuggestions";
import SearchOverlayRefinements from "@/components/SearchOverlayRefinements";
import SearchOverlaySectionMenu from "@/components/SearchOverlaySectionMenu";
import { useSearchContext } from "@/contexts/SearchContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { usePreventScroll } from "react-aria";
import { Hits, useInstantSearch, useSearchBox } from "react-instantsearch";

const listClassName = clsx("flex flex-col mx-0 px-0 space-y-1.5");
const itemClassName = clsx(
  "text-sm text-left font-body cursor-pointer hover:opacity-75 transition ease-out"
);

const SearchOverlayDesktop = ({ theme }) => {
  const router = useRouter();

  const { isDesktopOverlayOpen, setIsDesktopOverlayOpen } = useSearchContext();

  const handleOpenChange = (open: boolean) => {
    // setIsDesktopOverlayOpen(open);
  };

  // Using next router, when the route changes, close the overlay
  useEffect(() => {
    const handleRouteChange = () => {
      setIsDesktopOverlayOpen(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events, setIsDesktopOverlayOpen]);

  usePreventScroll({ isDisabled: !isDesktopOverlayOpen });

  return (
    <>
      <Dialog.Root
        open={isDesktopOverlayOpen}
        onOpenChange={handleOpenChange}
        modal={false}
      >
        <Dialog.Portal>
          <div
            className={clsx(
              "!top-header fixed inset-0 z-50 bg-zinc-950/50",
              {}
            )}
          />
          <Dialog.Content
            className={clsx("top-header fixed inset-x-0 z-[51]", {})}
            onOpenAutoFocus={(event) => {
              event.preventDefault();
            }}
            onEscapeKeyDown={() => setIsDesktopOverlayOpen(false)}
            onPointerDownOutside={(event) => {
              if (event.target.id !== "desktop-search-input") {
                setIsDesktopOverlayOpen(false);
              }
            }}
          >
            <Dialog.Close className={clsx("absolute right-0 top-0 w-16")} />
            {isDesktopOverlayOpen && <_Content theme={theme} />}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

const _Content = ({ theme }) => {
  const searchUrl = `/search`;

  return (
    <div className={clsx("w-full text-white")}>
      <div
        className={clsx("grid grid-cols-12 border-t ", {
          "border-zinc-500 bg-zinc-950 text-white focus:border-zinc-500":
            theme === "dark",
          "border-zinc-200 bg-white text-zinc-800 focus:border-zinc-200":
            theme === "light",
        })}
      >
        <div className={clsx("col-span-3 2xl:col-span-2")}>
          <_Suggestions theme={theme} />

          <div className={clsx("px-12 py-8")}>
            <_SearchHistory theme={theme} />
          </div>
        </div>
        <div className={clsx("col-span-9 2xl:col-span-10")}>
          <div
            className={clsx("h-full border-l", {
              "border-zinc-500": theme === "dark",
              "border-zinc-200": theme === "light",
            })}
          >
            <div className={clsx("relative flex items-center py-6 pl-12")}>
              <div>
                <SearchOverlaySectionMenu theme={theme} />
              </div>
              <div className={clsx("grow overflow-hidden pl-4")}>
                <SearchOverlayRefinements theme={theme} />
              </div>
            </div>
            <div className={clsx("px-12")}>
              <Hits
                hitComponent={(props) => {
                  return <_Hit {...props} index={props.hit.__position - 1} />;
                }}
                classNames={{
                  list: clsx(
                    "grid grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-x-6"
                  ),
                  item: clsx("col-span-1"),
                }}
              />
            </div>
            <div className={clsx("flex items-center justify-center pb-12")}>
              <Link href={searchUrl}>
                <a
                  className={clsx("button mx-auto", {
                    "button-light": theme === "dark",
                    "button-dark": theme === "light",
                  })}
                >
                  <div className={clsx("text-sm")}>All Results</div>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const _Hit = ({ hit, index, sendEvent }) => {
  const { setLastAlgoliaQueryId } = useSearchContext();

  const handleClick = () => {
    sendEvent("click", hit, "Recipe Clicked from Overlay");
    setLastAlgoliaQueryId(hit.__queryID);
  };

  const hideClass = clsx("pb-6", {
    "md:hidden lg:block": index >= 8, // Hide if index is 10 or more on 'lg' and smaller screens
    "lg:hidden xl:block": index >= 10, // Hide if index is 10 or more on 'lg' and smaller screens
    "xl:hidden 2xl:block": index >= 12, // Hide if index is 12 or more on 'xl' and smaller screens
    "2xl:hidden": index >= 14, // Hide if index is 14 or more on '2xl'
  });

  return (
    <EntryThumbnailCompact
      className={hideClass}
      entry={hit}
      onClick={handleClick}
    />
  );
};

const _Suggestions = ({ theme }) => {
  return (
    <div
      className={clsx("border-b px-12 py-8", {
        // hidden: !showSuggestions,
        "border-zinc-200": theme === "light",
        "border-zinc-500": theme === "dark",
      })}
    >
      <div className={clsx("space-y-4")}>
        <div className={clsx("text-sm text-zinc-400")}>How about...</div>

        <_QuerySuggestions />
      </div>
    </div>
  );
};

const _QuerySuggestions = () => {
  const router = useRouter();

  const { indexUiState } = useInstantSearch();

  const { query } = indexUiState;

  const { refine } = useSearchBox();

  const handleClick = (query) => {
    // Refine using the query
    refine(query);

    // Start pushing to the search results page
    router.push(`/search`);
  };

  return (
    <QuerySuggestions query={query} className={listClassName}>
      {(hits) =>
        hits.map((hit) => (
          <button
            key={hit.objectID}
            onClick={() => handleClick(hit.query)}
            className={clsx(itemClassName)}
          >
            {hit.query}
          </button>
        ))
      }
    </QuerySuggestions>
  );
};

const _SearchHistory = () => {
  const { setIndexUiState } = useInstantSearch();
  const { searchHistory, setSearchHistory } = useSearchContext();
  const router = useRouter();

  const handleClearHistory = () => {
    setSearchHistory([]);
  };

  const handleClick = (query) => {
    // Set the query
    setIndexUiState((prevIndexUiState) => ({
      ...prevIndexUiState,
      query,
    }));

    // Close the overlay
    //setIsOverlayOpen(false);

    // Start pushing to the search results page
    router.push(`/search`);
  };

  // Limit the items to 5
  const searchHistoryLimited = searchHistory.slice(0, 5);

  return (
    <div className={clsx("space-y-4")}>
      <div className={clsx("text-sm text-zinc-400")}>Recent searches</div>
      <ul className={clsx(listClassName)}>
        {searchHistoryLimited.map((query) => {
          return (
            <li
              key={query}
              className={clsx(itemClassName)}
              onClick={() => handleClick(query)}
            >
              <div>{query}</div>
            </li>
          );
        })}
      </ul>
      {searchHistory.length > 0 && (
        <button
          className={clsx(
            "flex items-center space-x-1 font-body text-xs transition ease-out hover:opacity-75"
          )}
          onClick={handleClearHistory}
        >
          <XMarkIcon className={clsx("h-4 w-4")} />
          <div>Clear history</div>
        </button>
      )}
    </div>
  );
};

export default SearchOverlayDesktop;
