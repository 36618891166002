import algoliasearch from "algoliasearch/lite";

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
);

export const DEFAULT_SEARCH_CHARACTER = "/";

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (
      requests.every(({ params }) => params.query == DEFAULT_SEARCH_CHARACTER)
    ) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: "",
          params: "",
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

export default searchClient;
