import Logotype from "components/MobLogotype";

const MaintenanceMode = () => {
  return (
    <div className="h-[100dvh] bg-zinc-800">
      <div className="MaintenanceMode">
        <div className="MaintenanceMode__content">
          <div className="MaintenanceMode__logotype">
            <Logotype />
          </div>
          <div className="MaintenanceMode__message">
            We're busy making a few changes to the website. We will be back up
            and running in an hour or two. In the meantime you can check us out
            on <a href="https://www.instagram.com/mob/">Instagram</a>.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceMode;
