const getSectionNameFromHandle = (handle: string): string => {
  switch (handle) {
    case "articles":
      return "Article";
    case "mealPlans":
      return "Meal Plan";
    case "recipes":
      return "Recipe";
    case "collections":
      return "Collection";
    case "products":
      return "Shop";
    case "mealPlanCollection":
      return "Meal Plan Collection";
    case "articleCollection":
      return "Article Collection";
    default:
      return "";
  }
};

export default getSectionNameFromHandle;
