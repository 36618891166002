import clsx from "clsx";

const Logo = ({ className = "" }) => {
  return (
    <svg
      viewBox="0 0 48 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M46.8881 8.34882C44.7881 2.04691 39.2424 -0.0416562 36.3522 3.76127C33.4036 7.6412 34.1378 16.4321 32.8425 17.0801C31.9412 17.5309 30.5891 13.6293 28.5024 0.672442L23.5243 0L23.5224 0.0136519C23.5161 0.0127768 23.5097 0.0119016 23.5034 0.0110265C21.2689 16.5839 20.4794 21.2542 20.2914 21.2402C19.9649 21.2159 22.6586 7.15708 19.4 2.70202C17.6859 0.358624 14.422 0.794259 12.5014 3.71734C9.37571 8.47431 10.0344 18.9478 9.56001 19.0601C9.1203 19.1644 10.0152 9.82235 7.42675 7.00744C5.74781 5.18159 2.64782 6.09959 1.04175 9.47143C-1.25785 14.2996 0.9423 21.142 1.11199 21.6512C3.21198 27.9531 8.75768 30.0417 11.6479 26.2387C14.5965 22.3588 13.8623 13.5679 15.1576 12.9199C16.0589 12.4691 17.4109 16.3707 19.4977 29.3276L24.4758 30L24.4777 29.9863C24.484 29.9872 24.4904 29.9881 24.4967 29.989C26.731 13.4163 27.5205 8.74595 27.7085 8.75995C28.035 8.78428 25.3413 22.8431 28.5999 27.2982C30.314 29.6416 33.5777 29.2059 35.4985 26.2828C38.6242 21.5259 37.9655 11.0524 38.4399 10.9401C38.8796 10.8357 37.9847 20.1778 40.5732 22.9927C42.2521 24.8186 45.3521 23.9006 46.9582 20.5287C49.2579 15.7004 47.0576 8.85779 46.8881 8.34882Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Logo;
