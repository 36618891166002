import useIsAuthPage from "@/hooks/useIsAuthPage";
import { useRouter } from "next/router";
import { useEffect } from "react";

const useSetPreviousUrl = () => {
  const router = useRouter();
  const isAuthPage = useIsAuthPage();

  useEffect(() => {
    const onRouteChangeStart = () => {
      // Never save the previous URL if the current URL is the login page
      if (isAuthPage) {
        return;
      }

      // if previousUrlOverride = true, don't update the previousUrl
      const previousUrlOverride = sessionStorage.getItem("previousUrlOverride");
      if (previousUrlOverride !== "true") {
        sessionStorage.setItem("previousUrl", router.asPath);
      }
      sessionStorage.setItem("previousUrlOverride", "false");
    };
    router.events.on("routeChangeStart", onRouteChangeStart);

    // Unassign event listener
    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthPage, router.asPath]);

  return null;
};

export default useSetPreviousUrl;
