import useCurrentUser from "@/hooks/useCurrentUser";

const useCurrentUserDietaryRequirements = () => {
  // Get the current user
  const { currentUser, isFetching, isLoggedIn } = useCurrentUser();

  const dietaryRequirements = currentUser?.dietaryRequirements?.map(
    (dietaryRequirement) => dietaryRequirement
  );

  const dietaryRequirementTitles = currentUser?.dietaryRequirements?.map(
    (dietaryRequirement) => dietaryRequirement.title
  );

  const dietaryRequirementIds = currentUser?.dietaryRequirements?.map(
    (dietaryRequirement) => dietaryRequirement.id
  );

  const dietaryRequirementfacetFilters =
    currentUser?.dietaryRequirements.map((dietaryRequirement) => {
      return `dietaryRequirements.title:${dietaryRequirement.title}`;
    }) || [];

  return {
    dietaryRequirements,
    dietaryRequirementTitles,
    dietaryRequirementIds,
    dietaryRequirementfacetFilters,
  };
};

export default useCurrentUserDietaryRequirements;
