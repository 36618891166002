import * as Fathom from "fathom-client";
import { useRouter } from "next/router";
import { useEffect } from "react";

const useFathom = () => {
  const router = useRouter();

  // Initialize Fathom when the app loads
  useEffect(() => {
    // Only initializze fathom in production
    if (process.env.NODE_ENV !== "production") return;

    // Note that we're using a custom domain: Fathom have deprecated this but still
    // allows its use; useful to avoid adblockers
    Fathom.load("VYBPFKNH", {
      url: "https://horse-thirtyeight.mob.co.uk/script.js",
      includedDomains: ["www.mob.co.uk"],
    });

    function onRouteChangeComplete(as, routeProps) {
      if (!routeProps.shallow) {
        Fathom.trackPageview();
      }
    }
    // Record a pageview when route changes
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default useFathom;
