import { makeAutoObservable } from "mobx";

export class CompetitionStore {
  constructor({ apiStore }) {
    makeAutoObservable(this);

    this.apiStore = apiStore;
  }

  fetchEntries = () => {
    const response = this.apiStore.axiosInstance({
      method: "GET",
      url: "actions/site-module/site/competition-entries",
    });

    return response;
  };

  checkEntry = async (entryId) => {
    const response = this.apiStore.axiosInstance({
      method: "GET",
      url: "actions/site-module/site/competition-entry",
      params: {
        entryId,
      },
    });

    return response;
  };

  createEntry = async (entryId) => {
    const response = this.apiStore.axiosInstance({
      method: "POST",
      url: "actions/site-module/site/create-competition-entry",
      data: {
        entryId,
      },
    });

    return response;
  };
}
