import { useStores } from "@/hooks/useStores";
import { observer } from "mobx-react";

const LogoutButton = ({ className = "" }) => {
  const { authStore } = useStores();

  return (
    <button
      className={className}
      onClick={() => {
        authStore.logout();
      }}
    >
      Logout
    </button>
  );
};

export default observer(LogoutButton);
