export function getAlgoliaFilters(rail): string {
  const includeFilters = [];
  const excludeFilters = [];

  for (const key in rail) {
    if (
      Array.isArray(rail[key]) &&
      (key.startsWith("include") || key.startsWith("exclude")) &&
      !key.endsWith("Recipes") // don't include includeRecipes or excludeRecipes here
    ) {
      rail[key].forEach((item) => {
        const filter = `${item.groupHandle}.title:"${item.title}"`;
        if (key.startsWith("exclude")) {
          excludeFilters.push(`NOT ${filter}`);
        } else {
          // For include filters
          includeFilters.push(filter);
        }
      });
    }
  }

  // We need to add any recipe exclusions to the excludeFilters
  if (rail.excludeRecipes && rail.excludeRecipes.length > 0) {
    rail.excludeRecipes.map((recipe) => {
      excludeFilters.push(`NOT objectID:${recipe.id}`);
    });
  }

  // Add any include recipe fileds
  if (rail.includeRecipes && rail.includeRecipes.length > 0) {
    rail.includeRecipes.map((recipe) => {
      includeFilters.push(`objectID:${recipe.id}`);
    });
  } else {
    // We'll never show batch recipes in normal rails, only if they're explicitly included
    excludeFilters.push("NOT partOfBatchMealPlan:true");
  }

  // Combine include filters using OR
  const includeFiltersString = includeFilters.join(" OR ");
  // Combine exclude filters using AND
  const excludeFiltersString = excludeFilters.join(" AND ");

  // Combine include and exclude filters
  const combinedFilters = [
    includeFiltersString && `(${includeFiltersString})`,
    excludeFiltersString,
  ]
    .filter(Boolean)
    .join(" AND ");

  return combinedFilters;
}

export default getAlgoliaFilters;
