import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";

type MobPlusFeaturesTourType = {
  className?: string;
};

function Check() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.25 3.08L6.862 13.083.96 7.401l2.08-2.162 3.822 3.679L15.17.919l2.08 2.162z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export const MobPlusFeaturesTour: React.FunctionComponent<
  MobPlusFeaturesTourType
> = ({ className = "" }) => {
  const [sellingPoints, setSellingPoints] = React.useState([
    "<p>Daily recipe inspiration <em>for all occasions</em></p>",
    "<p>Healthy dishes, midweek meals, comfort classics</p>",
    "<p>Delicious weekly meal plans curated by our food team </p>",
    "<p><em>A fully customisable</em> meal planner</p>",
    "<p>In-depth <em>nutritional information</em></p>",
    "<p>Adjustable<em> serving sizes</em> to match your needs</p>",
    "<p>Convenient filtering for <em>dietary preferences</em></p>",
    "<p><em>Save your favourite recipes + build recipe collections</em></p>",
  ]);
  const [sellingPointsIndex, setSellingPointsIndex] = React.useState(0);
  const [sellingPointsOffset, setSellingPointsOffset] = React.useState(3);
  const [numberOfSlides, setNumberOfSlides] = React.useState(0);

  // Calculate how many slides we'll have
  React.useEffect(() => {
    if (sellingPoints.length) {
      setNumberOfSlides(Math.ceil(sellingPoints.length / sellingPointsOffset));
    }
  }, [sellingPoints.length, sellingPointsOffset]);

  /*
  React.useEffect(() => {
    fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/plus-features.json`)
      .then((response) => response.json())
      .then((data) => {
        setSellingPoints(data);
      });
  }, []);
	*/

  // Rotate between selling points
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (sellingPointsIndex < sellingPoints.length / sellingPointsOffset - 1) {
        setSellingPointsIndex(sellingPointsIndex + 1);
      } else {
        setSellingPointsIndex(0);
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [sellingPointsIndex, sellingPoints.length, sellingPointsOffset]);

  // Handle keyboard events to go between slides
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        if (sellingPointsIndex > 0) {
          setSellingPointsIndex(sellingPointsIndex - 1);
        } else {
          setSellingPointsIndex(numberOfSlides - 1);
        }
      } else if (event.key === "ArrowRight") {
        if (sellingPointsIndex < numberOfSlides - 1) {
          setSellingPointsIndex(sellingPointsIndex + 1);
        } else {
          setSellingPointsIndex(0);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [sellingPointsIndex, numberOfSlides]);

  return (
    <div className={`flex h-full flex-col ${className}`}>
      <ul className="flex w-full flex-1 flex-col justify-center pl-0">
        <AnimatePresence exitBeforeEnter>
          <div className="mb-4 mt-4 flex w-full  flex-col justify-center gap-2 px-0 text-sm">
            {sellingPoints
              .slice(
                sellingPointsIndex * sellingPointsOffset,
                sellingPointsIndex * sellingPointsOffset + sellingPointsOffset
              )
              .map((point, index) => (
                <motion.li
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{
                    duration: 0.5,
                    delay: index * 0.3,
                    ease: [0.25, 0.46, 0.45, 0.94],
                  }}
                  className="items-top flex gap-2"
                  key={point}
                >
                  <div className="w-8 pt-2">
                    <Check />
                  </div>
                  <span
                    className="relative top-[3px] flex-1"
                    dangerouslySetInnerHTML={{ __html: point }}
                  />
                </motion.li>
              ))}
          </div>
        </AnimatePresence>
      </ul>

      <ul className="mx-0 mb-0 flex justify-center gap-2 px-0">
        {/* Make numberOfSlides amount of <li> */}
        {Array.from(Array(numberOfSlides).keys()).map((slide) => (
          <li
            key={slide}
            className={`transition-1000 inline-block h-3 w-3 cursor-pointer rounded-full transition ${
              slide === sellingPointsIndex ? "bg-white" : "scale-75 bg-white/30"
            }`}
            onClick={() => setSellingPointsIndex(slide)}
          />
        ))}
      </ul>
    </div>
  );
};

export default MobPlusFeaturesTour;
