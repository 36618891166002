import React, { useEffect, useState } from "react";
import useSWR from "swr";

const QuerySuggestions = ({ query, children, className = "" }) => {
  const [debouncedQuery, setDebouncedQuery] = useState(query || "");
  const [currentHits, setCurrentHits] = useState([]);

  // Update the debounced query after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query || "");
    }, 150);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  // Set the query on first mount
  useEffect(() => {
    setDebouncedQuery(query || "");
  }, []);

  const { data } = useSWR(
    !!debouncedQuery
      ? `/api/search/suggestions?q=${debouncedQuery}`
      : `/api/search/suggestions`
  );

  // Update currentHits if data has at least 5 hits
  useEffect(() => {
    if (data?.hits && data.hits.length >= 1) {
      setCurrentHits(data.hits);
    }
  }, [data]);

  // Render the children with currentHits as argument
  return (
    <div className={className}>
      {typeof children === "function" ? children(currentHits) : children}
    </div>
  );
};

export default QuerySuggestions;
