import { useEffect } from "react";
import aa from "search-insights";

const useIntializeAlgoliaInsights = () => {
  useEffect(() => {
    aa("init", {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
    });
  }, []);
};

export default useIntializeAlgoliaInsights;
