import apiClient from "@/lib/apiClient";
import { decodeJwt } from "jose";

// Creating this madness as a temporary solution to authenticating the user
// until we have a proper authentication system in place. This is used to communicate with api.mob.co.uk
// We're wrapping the authStore just to add a few extra methods, and avoid messing up the existing code, which is fragile and hasn't been updated in a long time.
export const createAuthStoreWrapper = (authStore) => {
  const generateAndSetJWT = async (user) => {
    // Only get what we need from the user object
    const {
      id,
      uid,
      email,
      firstName,
      lastName,
      plan,
      token: userToken,
    } = user;

    try {
      // Generate a JWT for the user - this is an api route because it needs to be done on the server, never the client
      const response = await fetch("/api/auth/generate-jwt", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user: { id, uid, email, firstName, lastName, plan, userToken },
        }),
      });

      const { token } = await response.json();

      // Set the jwt cookie, again on the server
      await fetch("/api/auth/set-jwt-cookie", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      });
      return { success: true, token };
    } catch (error) {
      console.error("Failed to generate or set JWT:", error);
      return { success: false, error };
    }
  };

  // Clear the jwt cookie, via the server. Used when the user logs out.
  const clearJWT = async () => {
    try {
      await fetch("/api/auth/clear-jwt-cookie", { method: "POST" });
    } catch (error) {
      console.error("Failed to clear JWT cookie:", error);
    }
  };

  // Implement a basic token cache to avoid fetching the token from the server on every request
  let tokenCache = {
    token: null,
    expiresAt: 0,
  };

  const CACHE_DURATION = 60000; // 1 minute

  // Get the token from the cache, or fetch it from the server if it's not in the cache or expired
  const getToken = async () => {
    const now = Date.now();
    if (tokenCache.token && now < tokenCache.expiresAt) {
      return tokenCache.token;
    }

    try {
      const response = await fetch("/api/auth/get-token");
      if (response.ok) {
        const { token } = await response.json();
        tokenCache = {
          token,
          expiresAt: now + CACHE_DURATION,
        };
        return token;
      }
    } catch (error) {
      console.error("Error getting token:", error);
    }
    return null;
  };

  // Check whether a token is expired
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = decodeJwt(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      return decodedToken.exp < currentTimestamp;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true;
    }
  };

  const refreshToken = async () => {
    try {
      const response = await fetch("/api/auth/refresh-token", {
        method: "POST",
      });
      if (response.ok) {
        const { token } = await response.json();
        await fetch("/api/auth/set-jwt-cookie", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token }),
        });
        return { success: true, token };
      }
      console.error("Failed to refresh token");
      return { success: false };
    } catch (error) {
      console.error("Error refreshing token:", error);
      return { success: false };
    }
  };

  const refreshTokenIfNeeded = async () => {
    const token = await getToken();
    if (!token || isTokenExpired(token)) {
      return await refreshToken();
    }
    return { success: true, token };
  };

  const getAuthHeaders = async () => {
    const { success, token } = await refreshTokenIfNeeded();
    return success ? { Authorization: `Bearer ${token}` } : {};
  };

  const wrappedLogin = async (...args) => {
    const result = await authStore.login(...args);
    if (result.success && !!result.user?.uid) {
      const jwtResult = await generateAndSetJWT(result.user);
      if (jwtResult.success && jwtResult.token) {
        apiClient.setAuthToken(jwtResult.token);
      }
    }
    return result;
  };

  const wrappedSignUp = async (...args) => {
    const result = await authStore.signUp(...args);
    if (result.success && !!result.uid) {
      const jwtResult = await generateAndSetJWT(result.user);
      if (jwtResult.success && jwtResult.token) {
        apiClient.setAuthToken(jwtResult.token);
      }
    }
    return result;
  };

  const wrappedLogout = async (...args) => {
    const result = await authStore.logout(...args);
    if (result.success) {
      await clearJWT();
      apiClient.clearAuthToken();
    }
    return result;
  };

  const fetchCurrentUser = async () => {
    try {
      // Fetch the user as normal
      const user = await authStore.fetchCurrentUser();

      // Make sure we have a user with a UID
      if (!!user && user?.uid) {
        // Refresh the token if needed
        const { success, token } = await refreshTokenIfNeeded();

        // If a token was generated successfully, set it
        if (success && token) {
          apiClient.setAuthToken(token);
        } else {
          // There's no valid JWT available, so we need to generate a new one
          const jwtResult = await generateAndSetJWT(user);
          // If a token was generated successfully, set it
          if (jwtResult.success && jwtResult.token) {
            apiClient.setAuthToken(jwtResult.token);
          }
        }
      } else {
        console.log("No user found or user has no UID");
      }

      return user;
    } catch (error) {
      console.error("Error fetching current user:", error);
      return null;
    }
  };

  const isUserAdmin = async () => {
    try {
      const token = await getToken();
      if (token) {
        const decodedToken = decodeJwt(token);
        return decodedToken.role === "elevated"; // eleveted if the user is a cms admin
      }
      return false;
    } catch (error) {
      console.error("Error checking admin status:", error);
      return false;
    }
  };

  return new Proxy(authStore, {
    get(target, prop) {
      if (prop === "login") return wrappedLogin;
      if (prop === "signUp") return wrappedSignUp;
      if (prop === "logout") return wrappedLogout;
      if (prop === "refreshTokenIfNeeded") return refreshTokenIfNeeded;
      if (prop === "refreshToken") return refreshToken;
      if (prop === "getAuthHeaders") return getAuthHeaders;
      if (prop === "fetchCurrentUser") return fetchCurrentUser;
      if (prop === "isUserAdmin") return isUserAdmin;
      return target[prop];
    },
  });
};
