async function fetchDeletePlanner(plannerUid: string): Promise<void> {
  try {
    const response = await fetch("/api/planner/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: plannerUid,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error deleting meal plan:", errorData.error);
      throw new Error(errorData.error);
    }

    console.log("Meal plan deleted successfully");
  } catch (error) {
    console.error("Error deleting meal plan:", error.message);

    throw error;
  }
}

export default fetchDeletePlanner;
