import useCurrentUser from "@/hooks/useCurrentUser";
import apiClient from "@/lib/apiClient";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

interface NewList {
  title: string;
  elementId?: number;
}

interface UseListsOptions {
  shouldFetch?: boolean;
}

const useLists = (options?: UseListsOptions) => {
  const { currentUser, isLoggedIn } = useCurrentUser();

  const userUid = currentUser?.uid;

  const { shouldFetch = true } = options || {};

  const { data, error, mutate, isLoading } = useSWR(
    shouldFetch && isLoggedIn ? "/lists" : null,
    (url) => apiClient.get(url, userUid),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  // Create list mutation
  const { trigger: createList, isMutating: isCreating } = useSWRMutation(
    "lists/create",
    async (key, { arg: newList }: { arg: NewList }) => {
      return apiClient.post("/lists", userUid, newList);
    },
    {
      onSuccess: (newListData) => {
        mutate(
          (currentData) => ({
            ...currentData,
            lists: [...(currentData?.lists || []), newListData.list],
          }),
          {
            revalidate: false,
          }
        );
      },
    }
  );

  // Move list mutation
  const { trigger: moveList, isMutating: isMoving } = useSWRMutation(
    "lists/move",
    async (key, { arg }: { arg: { listUid: string; targetIndex: number } }) => {
      return apiClient.put(`/lists/${arg.listUid}/move`, userUid, {
        targetIndex: arg.targetIndex,
      });
    },
    {
      onSuccess: () => mutate(),
    }
  );

  // Remove list mutation
  const { trigger: removeList, isMutating: isRemoving } = useSWRMutation(
    "lists/remove",
    async (key, { arg: listUid }: { arg: string }) => {
      return apiClient.delete(`/lists/${listUid}`, userUid);
    },
    {
      onSuccess: (_, listUid) => {
        mutate(
          (currentData) => ({
            ...currentData,
            lists: currentData?.lists.filter((l) => l.uid !== listUid) || [],
          }),
          {
            revalidate: false,
          }
        );
      },
    }
  );

  const lists = data?.lists || [];

  const listElementIds = lists.flatMap((list) => list.elementIds);

  return {
    lists,
    error,
    isLoading,
    createList,
    removeList,
    moveList,
    mutate,
    isCreating,
    isRemoving,
    isMoving,
    listElementIds,
  };
};

export default useLists;
