import clsx from "clsx";
import { motion } from "framer-motion";

const GradientWrapper = ({ children, className = "", theme = "purple" }) => {
  // Crossfade animation for each gradient
  const fadeVariants = {
    animate: (custom) => ({
      opacity: [0, 1, 1, 0],
      transition: {
        duration: 5,
        times: [0, 0.333, 0.666, 1], // Adjust the timing for crossfade overlap
        repeat: Infinity,
        repeatDelay: 0,
        delay: (custom * 4) / 3, // Stagger the animations without gaps
      },
    }),
  };

  const gradientClasses = {
    green: [
      "bg-green-gradient-100",
      "bg-green-gradient-200",
      "bg-green-gradient-300",
    ],
    purple: [
      "bg-purple-gradient-100 ",
      "bg-purple-gradient-200",
      "bg-purple-gradient-300",
    ],
    red: ["bg-red-gradient-100", "bg-red-gradient-200", "bg-red-gradient-300"],
  };

  // Accessing the correct class list based on the 'color' prop
  const colorGradients = gradientClasses[theme];

  return (
    <div
      className={clsx(
        className,
        "relative h-full w-full overflow-hidden rounded-full",
        colorGradients[0] // Apply the first gradient color as the background if needed
      )}
    >
      {colorGradients.map((gradientClass, index) => (
        <motion.div
          key={index}
          custom={index}
          className={clsx("absolute inset-0", gradientClass)}
          variants={fadeVariants}
          initial={{ opacity: 0 }}
          animate="animate"
        />
      ))}
      {children}
    </div>
  );
};

export default GradientWrapper;
