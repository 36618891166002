import useCurrentUser from "@/hooks/useCurrentUser";
import useSWR, { useSWRConfig } from "swr";

const useCurrentUserCollections = () => {
  const { mutate } = useSWRConfig();

  // Get the current user
  const { currentUser, isFetching } = useCurrentUser();

  // Use SWR to fetch the user's collections from /users/${currentUser.uid}/collections.json
  const { data, error, isLoading } = useSWR(
    !isFetching && currentUser
      ? `${process.env.NEXT_PUBLIC_CMS_URL}/users/${currentUser.uid}/collections.json`
      : null
  );

  const collections = data?.data || [];

  // Gather all the recipes that have been saved to this user's "Saved Recipes" collection
  let savedRecipeIds = [];
  collections
    .filter((c) => {
      return c.title === "Saved Recipes";
    })
    .map((c) => {
      savedRecipeIds = [...savedRecipeIds, ...c.recipeIds];
    });

  // Export a revalidate key so that we can revalidate this SWR hook from other components
  const revalidate = () => {
    mutate(
      `${process.env.NEXT_PUBLIC_CMS_URL}/users/${currentUser.uid}/collections.json`
    );
  };

  return {
    collections,
    savedRecipeIds,
    error,
    isLoading,
    revalidate,
  };
};

export default useCurrentUserCollections;
