"use client";

import { useMenuContext } from "@/contexts/MenuContext";
import { toggleButtonClicked } from "@/gtmEvents/mainMenu";
import pushToDataLayer from "@/lib/pushToDataLayer";
import clsx from "clsx";

const MenuToggle = () => {
  const { isOpen, toggle } = useMenuContext();

  const handleClick = () => {
    toggle();

    // GTM Event, with the opposite of the current state of isOpen so event is accurate
    pushToDataLayer(toggleButtonClicked, { isOpen: !isOpen });
  };

  return (
    <button
      className={clsx(
        "MenuToggle",
        "w-component h-component flex items-center justify-end"
      )}
      onClick={handleClick}
      title="Toggle Menu"
      aria-label="Toggle Menu"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className={clsx("h-6 w-6 stroke-[1.5] lg:h-8 lg:w-8 lg:stroke-[1]", {})}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 12h16.5"
          className={clsx("origin-center transition ease-out", {
            "translate-y-1": !isOpen,
            "rotate-45 scale-x-[0.8575]": isOpen,
          })}
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 12h16.5"
          className={clsx("origin-center transition ease-out", {
            "-translate-y-1": !isOpen,
            "-rotate-45 scale-x-[0.8575]": isOpen,
          })}
        />
      </svg>
    </button>
  );
};

export default MenuToggle;
