import useCurrentUser from "@/hooks/useCurrentUser";
import usePermission from "@/hooks/usePermission";
import Intercom, { boot, shutdown, update } from "@intercom/messenger-js-sdk";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";

const APP_ID = "pkb1wr24";

const useIntercom = () => {
  const router = useRouter();
  const { currentUser, isLoggedIn } = useCurrentUser();
  const hasIntercomPermission = usePermission("c:intercom");

  // Move this outside of the hook body
  Intercom({ app_id: APP_ID });

  const onRouteChangeComplete = useCallback(() => {
    update({
      user_id: currentUser.id,
      user_hash: currentUser.hash,
      email: currentUser.email,
    });
  }, [currentUser]);

  useEffect(() => {
    if (
      process.env.NODE_ENV !== "production" ||
      !hasIntercomPermission ||
      !isLoggedIn
    )
      return;

    const userForIntercom = {
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
      created_at: dayjs(currentUser.dateCreated).unix(),
      user_id: currentUser.id,
      plan: currentUser.plan,
      user_hash: currentUser.hash,
    };

    boot({
      app_id: APP_ID,
      ...userForIntercom,
    });

    router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
      shutdown();
    };
  }, [isLoggedIn, hasIntercomPermission, currentUser, onRouteChangeComplete]);

  return null;
};

export default useIntercom;
