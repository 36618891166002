import { useStores } from "hooks/useStores";

function useCurrentUser() {
  const { authStore } = useStores();
  const { currentUser, state } = authStore;

  return {
    currentUser,
    isFetching: state === "fetching",
    isLoggedIn: state === "loggedIn",
    isLoggedOut: state === "loggedOut",
  };
}

export default useCurrentUser;
