import { passwordHint, passwordRegex } from "lib/constants";
import { makeAutoObservable } from "mobx";
import * as Yup from "yup";

export class ProfileStore {
  constructor({ apiStore }) {
    makeAutoObservable(this);

    this.apiStore = apiStore;
  }

  isProfileWizardActive = false;

  setIsProfileWizardActive = (value) => {
    this.isProfileWizardActive = value;
  };

  steps = [
    "intro",
    "location",
    "cuisines",
    "types",
    "dietaryRequirements",
    "mailinglist",
    "welcome",
  ];

  activeStep = 0;

  setActiveStep = (value) => {
    this.activeStep = value;
  };

  paginateStep = (direction) => {
    this.activeStep += direction;
  };

  genders = {
    male: "Male",
    female: "Female",
    nonBinary: "Non-binary",
    notSpecified: "Prefer not to say",
  };

  avatars = ["burger", "garlic", "kebab", "pepper", "sandwich", "strawberry"];

  newsletterOptions = {
    yes: "Yes",
    no: "No",
  };

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Your first name is required"),
    lastName: Yup.string().required("Your last name is required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Your email address is required"),
    emailConfirm: Yup.string()
      .email("Please enter a valid email address")
      .oneOf([Yup.ref("email"), null], "Email address doesn't match")
      .required("You need to repeat your email address"),
    password: Yup.string()
      .required("Please enter a password")
      .matches(passwordRegex, passwordHint),
  });

  validationSchemas = {
    firstName: Yup.object().shape({
      firstName: Yup.string().required("Your first name is required"),
    }),
    lastName: Yup.object().shape({
      lastName: Yup.string().required("Your last name is required"),
    }),
    email: Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Your email address is required"),
    }),
    newPassword: Yup.object().shape({
      newPassword: Yup.string()
        .required("Please enter a password")
        .matches(passwordRegex, passwordHint),
    }),
  };
}
