import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useField } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

const TextInput = ({
  label,
  disabled = false,
  focusOnMount = false,
  type = "text",
  theme = "light",
  border = true,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef(null);
  const [field, meta] = useField(props);

  const className = clsx(
    "TextField",
    "flex flex-col space-y-1.5 relative",
    `TextField--${type}`,
    {
      "TextField--error": meta.error && meta.touched,
      "TextField--isDisabled": disabled,
    }
  );

  // Focus the field on mount
  useEffect(() => {
    if (!disabled && focusOnMount) inputRef.current.focus();
  }, [disabled, inputRef.current, focusOnMount]);

  const handleTogglePasswordView = () => {
    setShowPassword(!showPassword);
  };

  let fieldType;

  if (type === "password") {
    fieldType = showPassword ? "text" : "password";
  } else {
    fieldType = type;
  }

  const shouldAutocapitalize =
    field.name === "firstName" || field.name === "lastName";

  return (
    <div className={className}>
      {label && (
        <label className={clsx("field-label")} htmlFor={field.name}>
          {label}
        </label>
      )}
      <div className={clsx("relative")}>
        <input
          className={clsx("text-field", {
            "": theme === "light",
            "text-field-dark": theme === "dark",
          })}
          autoCapitalize={shouldAutocapitalize ? "words" : null}
          ref={inputRef}
          disabled={disabled}
          type={fieldType}
          aria-label={label}
          {...field}
          {...props}
        />
        {type === "password" && !disabled && (
          <button
            onClick={handleTogglePasswordView}
            type="button"
            className={clsx(
              "w-component h-component absolute inset-y-0 right-0 z-10 flex items-center justify-center text-zinc-500"
            )}
            tabIndex="-1"
          >
            {!showPassword && <EyeIcon className={clsx("h-5 w-5")} />}
            {showPassword && <EyeSlashIcon className={clsx("h-5 w-5")} />}
          </button>
        )}
      </div>

      <AnimatePresence initial={false}>
        {meta.error && meta.touched && (
          <motion.div className="TextField__error">
            <div className={clsx("font-body text-sm text-red-500")}>
              {meta.error}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TextInput;
