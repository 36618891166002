import GTMEvent from "@/types/GTMEvent";

/* Global Header events */
const category = "globalHeader";

export const loginPopoverToggleClicked = {
  category,
  name: "loginPopoverToggle",
  action: "clicked",
  label: "User clicked login popover toggle",
} as GTMEvent;

export const joinButtonClicked = {
  category,
  name: "joinButton",
  action: "clicked",
  label: "User clicked join button",
} as GTMEvent;

export const logoClicked = {
  category,
  name: "logo",
  action: "clicked",
  label: "User clicked logo",
} as GTMEvent;

export const searchBarFocused = {
  category,
  name: "searchBar",
  action: "focused",
  label: "User focused on search bar",
} as GTMEvent;

export const searchBarQuerySubmitted = {
  category,
  name: "searchBarQuery",
  action: "submitted",
  label: "User submitted query",
} as GTMEvent;

const globalHeader = {
  loginPopoverToggleClicked,
  joinButtonClicked,
  logoClicked,
  searchBarFocused,
  searchBarQuerySubmitted,
};

export default globalHeader;
