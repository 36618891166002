import clsx from "clsx";
import AnimatedCross from "components/AnimatedCross";
import { Minus, Time } from "components/Icons";
import TimerDisplay from "components/TimerDisplay";
import { AnimatePresence, motion } from "framer-motion";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useInterval } from "react-use";

const TimerList = observer(() => {
  const { timerStore } = useStores();
  const [isActive, setIsActive] = useState(true);

  const handleToggle = () => setIsActive(!isActive);

  useEffect(() => {
    if (timerStore.count === 0) setIsActive(false);
  }, [timerStore.count]);

  const className = clsx("TimerList", {
    "TimerList--isActive": isActive,
    "TimerList--isFinished": timerStore.haveAnyTimersFinished,
  });

  return (
    <AnimatePresence>
      {timerStore.count > 0 && (
        <motion.div
          className={className}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          layout
        >
          <button
            className="TimerList__toggle"
            type="button"
            onClick={handleToggle}
          >
            <Header isActive={isActive} setIsActive={setIsActive} />
          </button>
          <AnimatePresence>
            {isActive && (
              <motion.div
                className="TimerList__timers"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
              >
                {timerStore.timers.map((timer) => (
                  <Timer key={timer.id} timer={timer} />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

const Header = observer(({ isActive, setIsActive }) => {
  const [isIconAnimating, setIsIconAnimating] = useState(false);

  const { timerStore } = useStores();

  const applyAnimation = () => {
    setIsIconAnimating(true);
    setTimeout(() => {
      setIsIconAnimating(false);
    }, 2250);
  };

  useInterval(applyAnimation, timerStore.haveAnyTimersFinished ? 10000 : null);

  const className = clsx("TimerList__header");

  const iconClassName = clsx("TimerList__headerIcon", {
    "TimerList__headerIcon--isAnimating": isIconAnimating,
  });

  return (
    <div className={className} type="button">
      <div className={iconClassName}>
        <Time />
      </div>
      <div className="TimerList__headerLabel">
        Timers ({timerStore.timers.length})
      </div>
      {isActive && (
        <div className="TimerList__headerAction">
          <Minus />
        </div>
      )}
    </div>
  );
});

const Timer = ({ timer }) => (
  <div className="TimerList__timer">
    <div className="TimerList__display">
      <TimerDisplay timer={timer} />
    </div>
    <div className="TimerList__name">{timer.name}</div>
    <div className="TimerList__actions">
      <DeleteButton timer={timer} />
    </div>
  </div>
);

const DeleteButton = ({ timer }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleDelete = () => timer.delete();

  return (
    <button
      className="TimerList__delete"
      type="button"
      onClick={handleDelete}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      aria-label="Delete"
    >
      <AnimatedCross isActive={isHovering} />
    </button>
  );
};

export default TimerList;
