import { makeAutoObservable } from "mobx";

export class UIStore {
  constructor({ apiStore }) {
    makeAutoObservable(this);

    this.apiStore = apiStore;
  }

  // Screen

  /*
  screenWindow = null;

  setWindow = () => {
    // make sure the window is available
    if (typeof window === "object") {
      this.screenWindow = window;
      this.handleWindowSizeChange();
      this.screenWindow.addEventListener("resize", this.handleWindowSizeChange);
    }
  };

  windowWidth = 0;

  setWindowWidth = (width) => {
    this.windowWidth = width;
    return this.windowWidth;
  };

  windowHeight = 0;

  setWindowHeight = (height) => {
    this.windowHeight = height;
    return this.windowHeight;
  };
	*/

  /*
  handleWindowSizeChange = debounce(() => {
    const width = this.screenWindow.innerWidth;
    this.setWindowWidth(width);
    const height = this.screenWindow.innerHeight;
    this.setWindowHeight(height);
  }, 100);
	*/

  /*
  get isMobile() {
    return this.screenWindow ? this.windowWidth < 420 : false;
  }
	*/

  /*
  get isSmallerThanTablet() {
    return this.screenWindow ? this.windowWidth < 768 : false;
  }
	*/

  /*
  get isSmallerThanDesktop() {
    return this.screenWindow ? this.windowWidth < 1024 : false;
  }
	*/

  /*
  get isLargerThanWidest() {
    return this.screenWindow ? this.windowWidth > 1700 : false;
  }
	*/

  /*
  get contentHeight() {
    let height = this.windowHeight - headerHeightMobile;
    if (!this.isHeaderPinned) height += headerHeightMobile;

    return height;
  }
	*/

  // Scrolling

  /*
  scrollDistance = 0;

  setScrollDistance = (value) => {
    this.scrollDistance = value;
  };

  scrollHeight = 0;

  setScrollHeight = (value) => {
    this.scrollHeight = value;
  };

  scrollDirection = "down";

  setScrollDirection = (value) => {
    this.scrollDirection = value;
  };
	*/

  /*
  setScroll = () => {
    const handleScroll = () => {
      if (document.body.getBoundingClientRect().top > -this.scrollDistance) {
        this.scrollDirection = "up";
      } else {
        this.scrollDirection = "down";
      }

      this.setScrollDistance(document.body.getBoundingClientRect().top * -1);
      this.setScrollHeight(document.body.scrollHeight);
    };

    if (typeof window === "object") {
      this.scrollHeight = document.body.scrollHeight;

      window.addEventListener("scroll", handleScroll, {
        capture: false,
        passive: true,
      });
    }
  };
	*

  /*
  get hasScrolled() {
    return this.scrollHeight > 0 && this.scrollDistance > 0;
  }
	*/

  /*
  get scrollProgress() {
    return this.hasScrolled ? this.scrollDistance / this.scrollHeight : 0;
  }
	*/

  /*
  get scrolledQuarterway() {
    return this.hasScrolled && this.scrollProgress >= 0.25;
  }
	*/

  // Header

  /*
  isHeaderPinned = true;

  setIsHeaderPinned = (value) => {
    this.isHeaderPinned = value;
  };

  isHeaderTop = true;

  setIsHeaderTop = (value) => {
    this.isHeaderTop = value;
  };
	*/

  // For article pages, keep track of the image that is shown in the sidebar
  sidebarImage = null;

  setSidebarImage = (image) => {
    this.sidebarImage = image;
  };

  sidebarCaption = null;

  setSidebarCaption = (caption) => {
    this.sidebarCaption = caption;
  };

  // Recipes scroll restore

  /*
  recipesScrollDistanceFromBottom = null;

  setRecipesScrollDistanceFromBottom = (value) => {
    this.recipesScrollDistanceFromBottom = value;
  };
	*/
}
