import useUserElements from "@/src/lists/hooks/useUserElements";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { observer } from "mobx-react";

const AllSavedRecipesItem = ({ onClick }) => {
  const { elementIds } = useUserElements({
    fetchElementIdsOnly: true,
  });

  const handleClick = () => {
    onClick("all");
  };

  return (
    <button
      onClick={handleClick}
      className={clsx(
        "group relative flex w-full items-center border-b border-b-zinc-800 px-6 py-6 text-left last:border-b-0 focus:border-b-zinc-900 lg:px-12"
      )}
    >
      <div>
        <div className={clsx("relative")}>
          <div
            className={clsx(
              "relative isolate z-10 aspect-square w-18 rounded-md bg-zinc-700"
            )}
          ></div>
          <div
            className={clsx(
              "absolute left-1 top-1 z-0 aspect-square w-18 rounded-md border border-zinc-700"
            )}
          ></div>
        </div>
      </div>
      <div className={clsx("flex w-5/6 items-center px-6")}>
        <div className={clsx("flex flex-col")}>
          <div className={clsx("line-clamp-2 font-body text-sm lg:text-base")}>
            All Saved Recipes
          </div>
          <div
            className={clsx(
              "line-clamp-2 font-body text-xs text-zinc-500 lg:text-sm"
            )}
          >
            {elementIds.length} {elementIds.length === 1 ? "recipe" : "recipes"}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "ml-auto hidden h-full items-center justify-end opacity-0 group-hover:opacity-100 lg:flex"
        )}
      >
        <ArrowRightIcon className={clsx("h-5 w-5 text-zinc-50")} />
      </div>
    </button>
  );
};

export default observer(AllSavedRecipesItem);
