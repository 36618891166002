import Notification from "components/Notification";
import { padStart } from "lodash";
import React from "react";
import Toaster from "toasted-notes";

export const showNotification = (message, id = null) => {
  Toaster.closeAll();
  Toaster.notify(
    ({ onClose }) => <Notification onClose={onClose}>{message}</Notification>,
    {
      position: "bottom-left",
      id,
    }
  );
};

export const getDevice = () => {
  if (!navigator) return;
  const userAgent = navigator.userAgent;
  if (/mobile/i.test(userAgent)) {
      return 'mobile';
  } else if (/tablet/i.test(userAgent)) {
      return 'tablet';
  } else {
      return 'desktop';
  }
}

export const getRelatedEntry = (entry) =>
  entry.relatedEntry && entry.relatedEntry.length > 0
    ? entry.relatedEntry[0]
    : null;

export const getActionLabel = (entry) => {
  switch (entry.sectionHandle) {
    case "recipes":
      return "View Recipe";
    case "articles":
      if (entry.isCompetition) return "Enter Giveaway";
      return "View Article";
    case "products":
      return "View Product";
    case "collections":
      return "View Collection";
    default:
      return "View Article";
  }
};

export const getHref = (entry) => {
  switch (entry.sectionHandle) {
    case "recipes":
      return `/recipes/[slug]`;
    case "articles":
      return `/life/[slug]`;
    case "collections":
      return "/recipes/collections/[slug]";
    case "products":
      return "/shop"; // products don't link, just to the shop
    default:
      return `/`;
  }
};

export const getAs = (entry) => {
  switch (entry.sectionHandle) {
    case "recipes":
      return `/recipes/${entry.slug}`;
    case "articles":
      return `/life/${entry.slug}`;
    case "collections":
      return `/recipes/collections/${entry.slug}`;
    case "products":
      return "/shop"; // products don't link
    default:
      return `/`;
  }
};

export const getLink = (entry) => ({ href: getHref(entry), as: getAs(entry) });

export const getCategoryHref = (category) => {
  switch (category.groupHandle) {
    case "articleCategories":
      return `/life/category/${category.slug}`;
    default:
      return `/`;
  }
};

export const getGreeting = () => {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) {
    return "Morning";
  }
  if (curHr < 18) {
    return "Afternoon";
  }
  return "Evening";
};

export const formatTimeLeft = (seconds) => {
  const minutesLeft = Math.floor(seconds / 60);
  const secondsLeft = seconds - minutesLeft * 60;

  if (seconds <= 0) return "00:00";

  return `${padStart(minutesLeft, 2, 0)}:${padStart(secondsLeft, 2, 0)} `;
};

export const getSidebarImageForEntry = (entry) => {
  // If the entry has at least one content block of type image, that has showInSidebar set, we need to show that in the sidebar instead of the article's main image
  let sidebarImage =
    entry.image && entry.image.length > 0 ? entry.image[0] : null;
  let sidebarImageCaption;

  const sidebarImageBlocks = entry.body.filter(
    (b) => b.__typename === "body_image_BlockType" && b.showInSidebar && b.image
  );

  if (sidebarImageBlocks && sidebarImageBlocks.length > 0) {
    if (sidebarImageBlocks[0].image && sidebarImageBlocks[0].image.length > 0) {
      sidebarImage = sidebarImageBlocks[0].image[0];

      // If there's a caption, add it to the image object
      sidebarImageCaption = sidebarImageBlocks[0].caption;
      if (sidebarImageCaption) {
        sidebarImage.caption = sidebarImageCaption;
      }
    }
  }

  return sidebarImage;
};

export const nl2br = (str) => {
  const breakTag = "<br />";
  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};
