import EntryThumbnailCompact from "@/components/EntryThumbnailCompact";
import Loading from "@/components/Loading";
import EmptyState from "@/src/lists/components/EmptyState";
import useLists from "@/src/lists/hooks/useLists";
import useUserElements from "@/src/lists/hooks/useUserElements";
import { elementInListsWarning } from "@/src/lists/lib/language";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

const AllSavedRecipes = ({ onClose = () => null }) => {
  const { userElements, removeElement, isLoadingUserElements } =
    useUserElements();

  const handleClick = () => {
    onClose();
  };

  return (
    <div className={clsx("px-6 py-6 lg:px-12")}>
      <div
        className={clsx(
          "flex w-full items-center pb-6 text-zinc-50 lg:text-base"
        )}
      >
        <div className={clsx("flex items-center space-x-4")}>
          <button
            className={clsx(
              "h-component-sm w-component-sm flex items-center justify-center rounded-full bg-zinc-50 text-zinc-950"
            )}
            onClick={handleClick}
          >
            <ArrowLeftIcon className={clsx("h-4 w-4")} />
          </button>
          <div className={clsx("flex space-x-2 font-body text-sm")}>
            <div>All Saved Recipes</div>
            <div className={clsx("text-zinc-500")}>
              {userElements.length}{" "}
              {userElements.length === 1 ? "recipe" : "recipes"}
            </div>
          </div>
        </div>
      </div>

      {isLoadingUserElements && (
        <div className={clsx("flex justify-center")}>
          <Loading />
        </div>
      )}

      {!isLoadingUserElements && userElements.length === 0 && (
        <EmptyState>You haven't saved any recipes yet</EmptyState>
      )}

      {!isLoadingUserElements && userElements.length > 0 && (
        <div
          className={clsx(
            "grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
          )}
        >
          {userElements.map((userElement) => {
            return (
              <_UserElement
                key={userElement.uid}
                userElement={userElement}
                removeElement={removeElement}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const _UserElement = ({ userElement, removeElement }) => {
  const element = userElement.element;

  const { listElementIds } = useLists();

  // Map the element to the expected entry format
  const entry = {
    id: element.id,
    title: element.title,
    uri: element.uri,
    image: [element.image],
    sectionHandle: element.section.handle,
    limitAccessTo: element.limitAccessTo,
    premium: element.premium,
  };

  const handleRemove = () => {
    const elementExistsInOtherLists = listElementIds.some(
      (listElementId) => listElementId === element.id
    );

    if (elementExistsInOtherLists) {
      const confirmRemoval = window.confirm(elementInListsWarning);
      if (!confirmRemoval) return;
    }

    return removeElement(element.id);
  };

  return (
    <div key={userElement.uid} className={clsx("col-span-1 text-zinc-50")}>
      <EntryThumbnailCompact
        entry={entry}
        showSection={false}
        showRemoveButton
        onRemove={handleRemove}
      />
    </div>
  );
};

export default AllSavedRecipes;
