export const easeOutQuad = [0.25, 0.46, 0.45, 0.94];
export const easeInOutQuad = [0.45, 0, 0.55, 1];
export const transitionDefaults = { ease: easeOutQuad, duration: 0.3 };

export const searchYOffsetMobile = 120;
export const searchYOffsetDesktop = 200;

export const headerHeightMobile = 65;

export const maxHeroHeight = 720;

export const breakpoints = {
  mobile: 640,
  tablet: 768,
  desktop: 1024,
  wide: 1300,
  widest: 1500,
};

export const defaultContainerVariants = {
  hidden: {
    opacity: 0,
    transition: {
      ...transitionDefaults,
      staggerChildren: 0.125,
      staggerDirection: -1,
    },
  },
  show: {
    opacity: 1,
    transition: {
      ...transitionDefaults,
      delayChildren: 0.125,
      staggerChildren: 0.125,
    },
  },
};

export const defaultChildVariants = {
  hidden: { opacity: 0, y: 12.5, transition: transitionDefaults },
  show: { opacity: 1, y: 0, transition: transitionDefaults },
};

export const shortEn = {
  h: () => "hr",
  m: () => "mins",
};

export const googleTagManagerId = process.env.NEXT_PUBLIC_GTM ?? "GTM-WQ3WM62";

export const passwordRegex = /^(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
export const passwordHint =
  "Your password must be at least 8 characters long and contain a number";
