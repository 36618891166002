import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

export const NotificationsContext = React.createContext();

export const NotificationsContextProvider = ({ children }) => {
  const [showNotification, setShowNotification] = useState(null);
  const [dismissedNotificationIds, setDismissedNotificationIds] =
    useLocalStorage("dismissedNotificationIds", []);
  // const [dismissedNotificationIds, setDismissedNotificationIds] = useState([]);

  const value = {
    showNotification,
    setShowNotification,
    dismissedNotificationIds,
    setDismissedNotificationIds,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};
