import { useStores } from "@/hooks/useStores";
import React, { useState, useMemo } from "react";
import { autorun } from "mobx";

export const SubscriptionContext = React.createContext();

export const SubscriptionContextProvider = ({ children }) => {
  const [hasHadATrial, setHasHadATrial] = useState(false);
  const [plan, setPlan] = useState("fetching");
  const { authStore } = useStores();

  const value = {
    hasHadATrial,
    setHasHadATrial,
    plan,
    setPlan,
  };

  useMemo(() => {
    // We use autorun because auth related data is in MobX not React state
    autorun(() => {
      // Don't do anything if we're still fetching
      if (authStore.state === "fetching") return;

      // If the user is logged out, they can't view restricted Mob+ content
      if (authStore.isLoggedOut) {
        setPlan(0);
        setHasHadATrial(false);
      } else {
        // If the user is logged in, we can check their plan for appropriate permissions
        setPlan(authStore.currentUser.plan);
        setHasHadATrial(authStore.currentUser.hasHadATrial);
      }
    });
  }, []);

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};
