import { PlannerWithDaysAndRecipes } from "@/types/planner";

async function fetchCopyPlanner(
  plannerUid: string,
  newUserId: string
): Promise<{
  success: boolean;
  copiedPlanner?: PlannerWithDaysAndRecipes;
  error?: string;
}> {
  try {
    const response = await fetch("/api/planner/copy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: plannerUid,
        newUserId: newUserId,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error copying planner:", errorData.error);
      throw new Error(errorData.error);
    }

    const responseData = await response.json();

    console.log("Planner copied successfully:", responseData.copiedPlanner);

    return responseData;
  } catch (error) {
    console.error("Error copying planner:", error.message);

    throw error;
  }
}

export default fetchCopyPlanner;
