import { makeAutoObservable } from "mobx";
import Menu from "models/Menu";

export class MenuStore {
  constructor({ apiStore }) {
    makeAutoObservable(this);

    this.apiStore = apiStore;
  }

  // Keeps track of which tooltip is active
  activeSaveRecipe = null;

  setActiveSaveRecipe = (value) => {
    this.activeSaveRecipe = value;
  };

  defaultTitle = "Saved Recipes";

  isFetching = false;

  setIsFetching = (value) => {
    this.isFetching = value;
  };

  isSaving = false;

  menus = [];

  set = (value) => {
    this.menus = value;
  };

  fetch = () => {
    this.setIsFetching(true);

    return this.apiStore
      .axiosInstance({
        method: "GET",
        url: "menus.json",
      })
      .then(
        (response) => {
          this.setFromData(response.data.data);
          this.setIsFetching(false);
          return response;
        },
        (error) => {
          this.set([]);
          this.setIsFetching(false);
          return Promise.reject(error);
        }
      );
  };

  add = (menu) => this.menus.push(menu);

  remove = (menu) => this.menus.splice(this.getIndex(menu), 1);

  get = (menuId) =>
    this.menus.find(({ id }) => parseInt(id, 10) === parseInt(menuId, 10));

  getIndex = (menu) =>
    this.menus.findIndex(
      ({ id }) => parseInt(id, 10) === parseInt(menu.id, 10)
    );

  setFromData = (data) => {
    if (!data) return;

    this.set([]);

    data.map((d) => {
      if (!this.exists(d)) {
        const menu = new Menu(this, {
          id: d.id,
          title: d.title,
          recipes: d.recipes,
          dateCreated: d.dateCreated,
        });

        return this.menus.push(menu);
      }
    });
  };

  get recipes() {
    const recipes = [];

    this.menus.map((menu) =>
      menu.recipes.map((recipe) => {
        if (
          recipes.findIndex(
            ({ id }) => parseInt(id, 10) === parseInt(recipe.id, 10)
          ) === -1
        )
          recipes.push({
            ...recipe,
            sectionHandle: "recipes",
            menu: {
              id: menu.id,
              title: menu.title,
              link: menu.link,
              isDefault: menu.isDefault,
            },
          });
      })
    );

    return recipes;
  }

  get recipeIds() {
    return this.recipes.map((r) => r.id);
  }

  isRecipeSaved = (recipe) =>
    this.recipes.findIndex(
      ({ id }) => parseInt(id, 10) === parseInt(recipe.id, 10)
    ) > -1;

  exists = (menu) => {
    if (this.menus.length === 0) return false;

    return this.getIndex(menu) > -1;
  };

  getByTitle = (title) => this.menus.find((menu) => menu.title === title);

  create = (title, recipe = null) => {
    const existingMenu = this.getByTitle(title);

    if (existingMenu) {
      return existingMenu.addRecipe(recipe);
    }

    // Create the new menu model
    const newMenu = new Menu(this, { title });

    // Save it
    this.isSaving = true;

    newMenu.save().then(() => {
      this.isSaving = false;

      // Add the menu to the collection
      this.add(newMenu);

      // When it's saved, add a recipe if it exists
      if (recipe) newMenu.addRecipe(recipe);
    });

    return newMenu;
  };

  get defaultMenu() {
    return this.menus.find((m) => m.title === this.defaultTitle);
  }

  get nonDefaultMenus() {
    return this.menus.filter((m) => m.title !== this.defaultTitle);
  }

  get doesDefaultMenuExist() {
    return !!this.defaultMenu;
  }

  createDefaultMenu = (recipe = null) => this.create(this.defaultTitle, recipe);
}
