import { PlannerDayWithRecipes } from "@/types/planner";

async function fetchUpdatePlannerDays(
  plannerUid: string,
  days: PlannerDayWithRecipes[]
): Promise<PlannerDayWithRecipes[] | null> {
  try {
    const response = await fetch("/api/planner/days/update", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ days, plannerUid }),
    });

    if (response.ok) {
      const updatedData = await response.json();
      console.log("Days updated successfully");
      return updatedData.updatedPlannerDays;
    } else {
      const errorData = await response.json();
      console.error(
        "Error updating days:",
        { plannerUid, days },
        errorData.error
      );

      return null;
    }
  } catch (error) {
    console.error("Error updating days:", { plannerUid, days }, error.message);

    return null;
  }
}

export default fetchUpdatePlannerDays;
