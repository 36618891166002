import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import { createContext, useContext, useEffect, useState } from "react";

type TargetingValue = string | string[];

// These are the set keys as provided here: https://resources.freestar.com/docs/key-value-pair-targeting //
type Targeting = {
  articleID?: TargetingValue;
  Author?: TargetingValue;
  campaign?: TargetingValue;
  Category?: TargetingValue;
  content?: TargetingValue;
  env?: TargetingValue;
  keywords?: TargetingValue;
  Member?: TargetingValue;
  org?: TargetingValue;
  pageId?: TargetingValue;
  pagetype?: TargetingValue;
  plan?: TargetingValue;
  pos?: TargetingValue;
  post?: TargetingValue;
  PostID?: TargetingValue;
  section?: TargetingValue;
  section_type?: TargetingValue;
  segment?: TargetingValue;
  SiteURL?: TargetingValue;
  status?: TargetingValue;
  Subcategory?: TargetingValue;
  Tag?: TargetingValue;
  url?: TargetingValue;
};

export type AdConfigOptions = {
  targeting?: Targeting;
};

type AdContextType = {
  showAds: boolean;
  setShowAds: (isOpen: boolean) => void;
  setConfig: (config: AdConfigOptions) => void;
  config: AdConfigOptions;
};

// Provide initial context value
const initialContextValue: AdContextType = {
  showAds: true,
  setShowAds: () => false,
  setConfig: () => null,
  config: {},
};

export const AdContext = createContext<AdContextType>(initialContextValue);

export const AdContextProvider = ({ children }) => {
  const { plan } = useSubscriptionPermission(null);
  const [showAds, setShowAds] = useState(false);
  const [config, setConfig] = useState<AdConfigOptions>({});

  const value = { showAds, setShowAds, config, setConfig };

  // If the plan is 2, never show ads
  if (plan === 2) {
    value.showAds = false;
  }

  // Reconfigure every time we change routes
  useEffect(() => {
    window.freestar = window.freestar || {};
    window.freestar.queue = window.freestar.queue || [];
    window.freestar.config = window.freestar.config || {};
    window.freestar.config.disabledProducts = {
      video: true,
      stickyFooter: true,
    };

    window.freestar.queue.push(function () {
      window.googletag.pubads().set("page_url", "https://www.mob.co.uk/");

      // Configure targeting
      if (config.targeting) {
        Object.entries(config.targeting).forEach(([key, value]) => {
          window.googletag.pubads().setTargeting(key, value);
        });
      }
    });
  }, [config.targeting]);

  return <AdContext.Provider value={value}>{children}</AdContext.Provider>;
};

export const useAdContext = (options?: AdConfigOptions): AdContextType => {
  const context = useContext(AdContext);

  if (context === undefined) {
    throw new Error("useAdContext must be used within a AdProvider");
  }

  // Only update the configuration if it's different
  useEffect(() => {
    if (options && JSON.stringify(options) !== JSON.stringify(context.config)) {
      context.setConfig(options);
    }
  }, [options, context]);

  return context;
};
