import {
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  RedditIcon,
  SpotifyIcon,
  TikTokIcon,
  YouTubeIcon,
} from "@/components/SocialIcons";
import clsx from "clsx";

const links = [
  {
    key: "facebook",
    url: "https://www.facebook.com/mobkitchen",
    icon: FacebookIcon,
  },

  {
    key: "instagram",
    url: "https://www.instagram.com/mob",
    icon: InstagramIcon,
  },
  /*
  {
    key: "reddit",
    url: "https://www.reddit.com/r/MobKitchen/",
    icon: RedditIcon,
  },
	*/
  {
    key: "tiktok",
    url: "https://www.tiktok.com/@mob",
    icon: TikTokIcon,
  },
  {
    key: "youtube",
    url: "https://www.youtube.com/channel/UCZh_x46-uGGM7PN4Nrq1-bQ",
    icon: YouTubeIcon,
  },
  /*
  {
    key: "spotify",
    url: "https://open.spotify.com/user/a21g7yr8ywq5mbf6hvbgxjr25?si=NnXin7XTQuGr44zx6zxJ8A",
    icon: SpotifyIcon,
  },
	*/
  {
    key: "pinterest",
    url: "https://www.pinterest.co.uk/mobkitchen/",
    icon: PinterestIcon,
  },
];

const SocialLinks = () => (
  <div className={clsx("SocialLinks", "")}>
    <div className={clsx("SocialLinks__content", "flex space-x-4")}>
      {links.map((link) => {
        const Icon = link.icon;

        return (
          <a
            key={link.key}
            className={clsx(
              "SocialLinks__link",
              "flex h-7 w-7 items-center justify-center [&_svg]:block [&_svg]:w-full"
            )}
            href={link.url}
            aria-label={`Go to ${link.key} profile`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon />
          </a>
        );
      })}
    </div>
  </div>
);

export default SocialLinks;
