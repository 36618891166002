import useCurrentUser from "@/hooks/useCurrentUser";
import apiClient from "@/lib/apiClient";
import { useCallback, useMemo, useState } from "react";
import useSWR, { mutate as mutateKey } from "swr";
import useSWRMutation from "swr/mutation";

interface UseListProps {
  listUid: string;
  initialPage?: number;
  shouldFetch?: boolean;
}

const useList = ({
  listUid,
  initialPage = 0,
  shouldFetch = true,
}: UseListProps) => {
  const { currentUser, isLoggedIn } = useCurrentUser();
  const userUid = currentUser?.uid;
  const [page, setPage] = useState(initialPage);

  const {
    data: listData,
    error,
    mutate,
    isLoading,
  } = useSWR(
    shouldFetch && isLoggedIn && listUid ? `/lists/${listUid}` : null,
    () => (shouldFetch ? apiClient.get(`/lists/${listUid}`, userUid) : null),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const list = listData?.list || {};
  const allListUserElements = list.listUserElements || [];

  const listUserElements = useMemo(() => {
    const startIndex = page * 24;
    return allListUserElements.slice(startIndex, startIndex + 24);
  }, [allListUserElements, page]);

  const loadMore = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  const update = async (updatedList) => {
    const updatedListData = await apiClient.put(
      `/lists/${updatedList.uid}`,
      userUid,
      updatedList
    );

    mutate(updatedListData, false);
    return updatedListData;
  };

  const { trigger: remove } = useSWRMutation(
    `/lists/${listUid}`,
    async (url) => {
      return await apiClient.delete(url, userUid);
    },
    {
      onSuccess: () => {
        // Optionally, you can invalidate or remove the list from other caches here
        mutateKey(
          "/lists",
          (currentData) => {
            // Loop through currentData.lists and remove the list with the matching uid
            return {
              ...currentData,
              lists: currentData.lists.filter((list) => list.uid !== listUid),
            };
          },
          {
            revalidate: false,
          }
        );
      },
    }
  );

  const { trigger: addElement } = useSWRMutation(
    `/lists/${listUid}/elements`,
    async (url, { arg: elementId }: { arg: number }) => {
      return await apiClient.post(url, userUid, { elementId });
    },
    {
      onSuccess: (newListUserElementData) => {
        const { listUserElement } = newListUserElementData;

        mutate(
          (currentData) => {
            // Make the new elementIds, ensuring it's unique
            const newElementIds = Array.from(
              new Set([
                ...(currentData.list.elementIds || []),
                listUserElement.userElement?.element?.id,
              ])
            );

            return {
              ...currentData,
              list: {
                ...currentData.list,
                listUserElements: [
                  ...currentData.list.listUserElements,
                  listUserElement,
                ],
                elementCount: newElementIds.length,
                elementIds: newElementIds,
              },
            };
          },
          {
            revalidate: false,
          }
        );

        // Update other relevant caches
        mutateKey(
          `/lists/elements/ids`,
          (currentData) => {
            // Make the new elementIds, ensuring it's unique
            const newElementIds = Array.from(
              new Set([
                ...(currentData.elementIds || []),
                listUserElement.userElement?.element?.id,
              ])
            );

            return {
              ...currentData,
              elementIds: newElementIds,
            };
          },
          {
            revalidate: false,
          }
        );

        mutateKey(
          `/lists`,
          (currentData) => {
            // Make sure that elementIds on the list are updated
            return {
              ...currentData,
              lists: currentData.lists.map((list) =>
                list.uid === listUid
                  ? {
                      ...list,
                      elementIds: [
                        ...list.elementIds,
                        listUserElement.userElement?.element?.id,
                      ],
                    }
                  : list
              ),
            };
          },
          {
            revalidate: false,
          }
        );
      },
    }
  );

  const { trigger: removeElement } = useSWRMutation(
    `/lists/${listUid}/elements`,
    async (url, { arg: elementId }: { arg: number }) => {
      return await apiClient.delete(`${url}/${elementId}`, userUid);
    },
    {
      onSuccess: (deletedListUserElementData) => {
        const userElement =
          deletedListUserElementData?.listUserElement?.userElement;

        mutate(
          (currentData) => ({
            ...currentData,
            list: {
              ...currentData.list,
              listUserElements: currentData.list.listUserElements.filter(
                (lue) => lue.userElement.element.id !== userElement.element.id
              ),
              elementCount: Math.max(
                (currentData.list.elementCount || 0) - 1,
                0
              ),
              elementIds: (currentData.list.elementIds || []).filter(
                (id) => id !== userElement.element.id
              ),
            },
          }),
          {
            revalidate: false,
          }
        );

        // Update other relevant caches
        mutateKey(
          `/lists`,
          (currentData) => {
            // Make sure that elementIds on the list are updated
            return {
              ...currentData,
              lists: currentData.lists.map((list) =>
                list.uid === listUid
                  ? {
                      ...list,
                      elementIds: list.elementIds.filter(
                        (id) => id !== userElement.element.id
                      ),
                    }
                  : list
              ),
            };
          },
          {
            revalidate: false,
          }
        );
      },
    }
  );

  const moveElement = useSWRMutation(
    `/lists/${listUid}/elements`,
    async (
      url,
      { arg }: { arg: { elementId: number; targetIndex: number } }
    ) => {
      return await apiClient.put(`${url}/${arg.elementId}/move`, userUid, {
        targetIndex: arg.targetIndex,
      });
    },
    {
      onSuccess: (movedListUserElementData) => {
        // Implement the mutation logic here
        mutate((currentData) => {
          // Update the order of listUserElements based on the new index
          // You'll need to implement this logic based on your data structure
          return currentData;
        }, false);
      },
    }
  );

  return {
    list,
    update,
    remove,
    listUserElements,
    error,
    isLoading,
    loadMore,
    addElement,
    removeElement,
    moveElement,
    mutate,
    hasMore: allListUserElements.length > (page + 1) * 24,
  };
};

export default useList;
