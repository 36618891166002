const getPaywallConfigFromEntry = (entry) => {
  const type = entry.typeHandle || entry.sectionHandle;

  return type === "recipe" || type === "recipes"
    ? {
        isOpen: true,
        isClosable: true,
        title: entry.title,
        variant: "recipeBookmark",
      }
    : {
        // Generic one for other entry types
        isOpen: true,
        isClosable: true,
        title: entry.title,
        variant: "default",
      };
};

export default getPaywallConfigFromEntry;
