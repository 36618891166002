import Spinner from "@/components/Spinner";
import { DEFAULT_SEARCH_CHARACTER } from "@/lib/algoliaSearchClient";
import {
  ArrowRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useInstantSearch, useSearchBox } from "react-instantsearch";

const SearchBar = ({
  searchAsYouType = false,
  autoFocus = false,
  theme = "dark",
  className = "",
  placeholder = "Search for recipes, ingredients, etc...",
  onFocus = () => null,
  onBlur = () => null,
  onSubmit = () => null,
  inputId = "",
  inputClassName = "", // this is appended to the input class, for refinements (normally bg colour)
  showSubmitButton = true, // submit button can be hidden for those instances where searchAsYouType is enough
  submitButtonClassName = "", // this is appended to the button class, for refinements (normally bg colour)
  initialValue = null,
}) => {
  const { refine, query } = useSearchBox();
  const { status } = useInstantSearch();

  const [value, setValue] = useState(
    initialValue !== null ? initialValue : query
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isMounted = useRef(true);
  const inputRef = useRef(null);

  const router = useRouter();

  const isSearching = status === "stalled"; // don't use loading since it's too fast

  const showSpinner = isSubmitting || isSearching;

  const setQuery = useCallback(
    (newQuery) => {
      if (newQuery !== DEFAULT_SEARCH_CHARACTER) {
        if (searchAsYouType) {
          refine(newQuery);
        }
      }
    },
    [searchAsYouType]
  );

  /*
		Handlers
		*/

  // When submitted and we're not on the /search page, navigate to it. The query refinement will already be in place.
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // If showSubmitButton is false, don't submit the form
    if (!showSubmitButton) {
      return;
    }

    // Set submitting to true
    setIsSubmitting(true);

    // If the current page isn't the search page, navigate to it. And once the transition has been completed, refine the search query.
    if (router.pathname !== "/search") {
      if (inputRef.current.value !== DEFAULT_SEARCH_CHARACTER) {
        refine(inputRef.current.value);
      }

      router.push("/search");
    }

    // If the current page is the search page, refine the search query.
    if (router.pathname === "/search") {
      if (inputRef.current.value !== DEFAULT_SEARCH_CHARACTER) {
        refine(inputRef.current.value);
      }

      // Jump to the top of the page
      window.scrollTo(0, 0);

      // If we're on the search page, set submitting to false since there's no page transition.
      setIsSubmitting(false);
    }

    // Call the onSubmit callback
    onSubmit(inputRef.current.value);
  };

  const handleFocus = () => {
    // When the input is focused, and we're not on /search, set the query to empty in order to fetch all results
    if (router.pathname !== "/search") {
      refine("");
    }

    // If the input already has a value, set the query to that value
    if (value !== "" && value !== DEFAULT_SEARCH_CHARACTER) {
      refine(value);
    }

    onFocus();
  };

  const handleBlur = () => {
    onBlur();
  };

  /*
		Effects
		*/

  // Track state of mounted component
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // If searchAsYouType is enabled, set the query to the value of value
  useEffect(() => {
    if (searchAsYouType && value !== "") {
      const handler = setTimeout(() => {
        setQuery(value);
      }, Number(process.env.NEXT_PUBLIC_QUERY_DEBOUCE_IN_MS) ?? 300);

      return () => {
        clearTimeout(handler);
      };
    }

    if (searchAsYouType && value === "") {
      setQuery("");
    }
  }, [value, searchAsYouType, setQuery]);

  // If we're not searching as we type, and then query is removed, reset the search query
  // by setting the input value to an empty string
  useEffect(() => {
    if (!searchAsYouType && query === "") {
      setValue("");
    }
  }, [query, searchAsYouType]);

  // When navigating to a new page, set isSubmitting to false
  useEffect(() => {
    const handleRouteChangeComplete = () => {
      setIsSubmitting(false);
    };

    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, []);

  return (
    <div
      className={clsx(
        "h-component relative w-full rounded-full border-0 bg-white",
        className
      )}
    >
      {showSubmitButton && (
        <div
          className={clsx(
            "pointer-events-none absolute inset-y-1 right-1 w-16 rounded-r-full bg-gradient-to-r from-transparent via-zinc-100 to-zinc-100"
          )}
        ></div>
      )}
      <div
        className={clsx(
          "w-component h-component absolute inset-y-0 left-0 z-10 flex items-center justify-center rounded-full text-zinc-500"
        )}
      >
        {showSpinner && <Spinner size="md" />}
        {!showSpinner && <MagnifyingGlassIcon className={clsx("h-5 w-5")} />}
      </div>
      <form
        action=""
        className={clsx("flex w-full")}
        role="search"
        noValidate
        onSubmit={handleSubmit}
        onReset={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setQuery("");

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <input
          ref={inputRef}
          id={inputId}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={placeholder}
          spellCheck={false}
          maxLength={128}
          type="search"
          className={clsx(
            "h-component flex flex-1 appearance-none rounded-full pl-9 font-body text-sm placeholder:text-zinc-500 lg:pl-10",
            inputClassName,
            {
              "lg:pr-10": showSubmitButton,
              "": theme === "dark",
              "bg-zinc-100": theme === "light",
            }
          )}
          value={value !== DEFAULT_SEARCH_CHARACTER ? value : ""}
          onChange={(event) => {
            setValue(event.currentTarget.value);
          }}
          autoFocus={autoFocus}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        {showSubmitButton && (
          <button
            type="submit"
            className={clsx(
              "h-component w-component group absolute inset-y-0 right-0 z-20 flex items-center justify-center rounded-full",
              submitButtonClassName
            )}
          >
            <div
              className={clsx(
                "absolute inset-y-[2px] left-[2px] right-[3px] flex items-center justify-center rounded-full  transition ease-out ",
                {
                  "bg-zinc-200 group-hover:bg-zinc-200": theme === "dark",
                  "bg-zinc-200 group-hover:bg-zinc-300": theme === "light",
                }
              )}
            >
              <ArrowRightIcon className={clsx("h-4 w-4")} />
            </div>
          </button>
        )}
      </form>
    </div>
  );
};

export default SearchBar;
