import useCurrentUser from "@/hooks/useCurrentUser";
import apiClient from "@/lib/apiClient";
import useSwr, { mutate as mutateKey } from "swr";
import useSWRMutation from "swr/mutation";

const useUserElements = (options?: {
  shouldFetch?: boolean;
  fetchElementIdsOnly?: boolean;
  limit?: number;
}) => {
  const {
    shouldFetch = true,
    limit = 1280,
    fetchElementIdsOnly = false,
  } = options || {};

  const { currentUser, isLoggedIn } = useCurrentUser();
  const userUid = currentUser?.uid;

  const {
    data: elementIdsData,
    error: elementIdsError,
    mutate: mutateElementIds,
    isLoading: isLoadingElementIds,
  } = useSwr(
    shouldFetch && isLoggedIn ? "/lists/elements/ids" : null,
    (url) => apiClient.get(url, userUid),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const elementIds = elementIdsData?.elementIds || [];

  const {
    data: userElementsData,
    error: userElementsError,
    mutate: mutateUserElements,
    isLoading: isLoadingUserElements,
  } = useSwr(
    shouldFetch && isLoggedIn && !fetchElementIdsOnly
      ? "/lists/elements"
      : null,
    (url) => apiClient.get(url, userUid, { limit }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const userElements = userElementsData?.userElements || [];

  /*
		Add element
	*/
  const { trigger: addElement, isMutating: isAdding } = useSWRMutation(
    "lists/elements/add",
    async (key, { arg: elementId }: { arg: number }) => {
      return apiClient.post(`/lists/elements`, userUid, { elementId });
    },
    {
      onSuccess: (response) => {
        if (response.success) {
          // Add the id of the element to the ids array
          mutateElementIds(
            (currentData) => ({
              ...currentData,
              elementIds: [
                ...(currentData?.elementIds || []),
                response.userElement?.element?.id,
              ],
            }),
            {
              revalidate: false,
            }
          );

          if (!fetchElementIdsOnly) {
            mutateUserElements();
          }
        } else {
          console.error("Error adding element", response.error);
        }
      },
    }
  );

  /*
		Remove element
	*/
  const { trigger: removeElement, isMutating: isRemoving } = useSWRMutation(
    "lists/elements/remove",
    async (key, { arg: elementId }: { arg: number }) => {
      return apiClient.delete(`/lists/elements/${elementId}`, userUid);
    },
    {
      onSuccess: (response) => {
        if (response.success) {
          // Remove the id of the element from the ids array
          mutateElementIds(
            (currentData) => ({
              ...currentData,
              elementIds: currentData?.elementIds.filter(
                (id) => id !== response.userElement?.element?.id
              ),
            }),
            {
              revalidate: false,
            }
          );

          if (!fetchElementIdsOnly) {
            mutateUserElements();
          }

          // We also need to remove this from all lists
          mutateKey(
            "/lists",
            (currentData) => ({
              ...currentData,
              lists: currentData.lists.map((list) => ({
                ...list,
                elementIds: list.elementIds.filter(
                  (id) => id !== response.userElement?.element?.id
                ),
              })),
            }),
            { revalidate: false }
          );
        } else {
          console.error("Error removing element", response.error);
        }
      },
    }
  );

  return {
    elementIds,
    elementIdsError,
    isLoadingElementIds,
    mutateElementIds,
    userElements,
    userElementsError,
    isLoadingUserElements,
    mutateUserElements,
    addElement,
    removeElement,
    isAdding,
    isRemoving,
  };
};

export default useUserElements;
