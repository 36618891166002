import clsx from "clsx";

const EmptyState = ({ children }) => {
  return (
    <div
      className={clsx(
        "rounded-xl border border-dashed border-zinc-700 p-6 text-center font-body text-sm text-zinc-500"
      )}
    >
      {children}
    </div>
  );
};

export default EmptyState;
