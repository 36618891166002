import { useMemo } from "react";
import { useCurrentRefinements } from "react-instantsearch";

const useHasRefinements = () => {
  const { items } = useCurrentRefinements({
    excludedAttributes: ["section.name", "mobPlus", "query"],
  });

  const { hasRefinements, count: refinementsCount } = useMemo(() => {
    // Count all items[] > refinements[] to check if we have any refinements
    const count = items.reduce((acc, item) => acc + item.refinements.length, 0);
    return {
      hasRefinements: count > 0,
      count, // This is the total number of refinements
    };
  }, [items]);

  return { hasRefinements, count: refinementsCount };
};

export default useHasRefinements;
