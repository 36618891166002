import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

const Notification = ({ children, onClose }) => (
  <div className={clsx("Notification", "rounded-2xl")}>
    <div className={clsx("Notification__content", "")}>
      <div className="Notification__message">{children}</div>

      <button
        className="Notification__closeButton"
        onClick={onClose}
        type="button"
        aria-label="Close notification"
      >
        <XMarkIcon className={clsx("h-5 w-5")} />
      </button>
    </div>
  </div>
);

export default Notification;
