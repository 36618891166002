import GTMEvent from "@/types/GTMEvent";

export function pushToDataLayer(event: GTMEvent, additionalData?: object) {
  const handle = `${event.category}.${event.name}.${event.action}`;

  if (process.env.NODE_ENV === "development")
    console.log(`[Dev] Pushing GTMEvent ${handle}`, additionalData);

  window.dataLayer = window.dataLayer || [];
  const fullEventData = {
    event: handle,
    ...event,
    data: { ...event.data, ...additionalData },
  };
  window.dataLayer.push(fullEventData);
}

export default pushToDataLayer;
