import React, { useState } from "react";

export const NavigationContext = React.createContext();

export const NavigationContextProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const [activeId, setActiveId] = useState(false);
  const [isMobileNavigationActive, setIsMobileNavigationActive] =
    useState(false);
  const [isDesktopNavigationActive, setIsDesktopNavigationActive] =
    useState(false);

  const value = {
    isActive,
    setIsActive,
    activeId,
    setActiveId,
    isMobileNavigationActive,
    setIsMobileNavigationActive,
    isDesktopNavigationActive,
    setIsDesktopNavigationActive,
  };
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};
