import React, { useCallback, useEffect, useState } from "react";

const contexts = [
  { key: "recipes", label: "Recipes", href: "/recipes" },
  { key: "life", label: "Articles", href: "/life/search" },
  {
    key: "collections",
    label: "Collections",
    href: "/recipes/collections/search",
  },
];

export const SearchContext = React.createContext({
  currentContext: contexts[0],
  setCurrentContext: () => {
    return;
  },
  query: "",
  setQuery: () => {
    return;
  },

  isDesktopOverlayOpen: false,
  setIsDesktopOverlayOpen: (value) => {
    return value;
  },
  isMobileOverlayOpen: false,
  setIsMobileOverlayOpen: (value) => {
    return value;
  },
  searchBarRightEdge: 0,
  setSearchBarRightEdge: () => {
    return;
  },
  searchHistory: [],
  setSearchHistory: (queries: string[]) => {
    return queries;
  },
  addToSearchHistory: (query: string) => {
    return query;
  },
  indexName: process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME,
  setIndexName: (indexName: string) => {
    return indexName;
  },
  lastAlgoliaQueryId: null,
  setLastAlgoliaQueryId: (value: string) => {
    return value;
  },
  isMobileFilterOverlayOpen: false,
  setIsMobileFilterOverlayOpen: (value: boolean) => {
    return value;
  },
});

export const SearchContextProvider = ({ children }) => {
  const [currentContext, setCurrentContext] = useState(contexts[0]);
  const [query, setQuery] = useState("");
  const [indexName, setIndexName] = useState(
    process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME
  );
  const [isDesktopOverlayOpen, setIsDesktopOverlayOpen] = useState(false);
  const [isMobileOverlayOpen, setIsMobileOverlayOpen] = useState(false);
  const [isMobileFilterOverlayOpen, setIsMobileFilterOverlayOpen] =
    useState(false);

  const [searchBarRightEdge, setSearchBarRightEdge] = useState(0);
  const [searchHistory, setSearchHistory] = useState([]);
  const [lastAlgoliaQueryId, setLastAlgoliaQueryId] = useState(null);

  // Load searchHistory from localStorage
  useEffect(() => {
    const searchHistory = localStorage.getItem("searchHistory");
    if (searchHistory) {
      setSearchHistory(JSON.parse(searchHistory));
    }
  }, []);

  // Whenever searchHistory changes, save it to localStorage
  useEffect(() => {
    localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
  }, [searchHistory]);

  // Add query to searchHistory
  const addToSearchHistory = useCallback(
    (query) => {
      // Check this query is not already in the search history
      const isAlreadyInHistory = searchHistory.includes(query);
      if (isAlreadyInHistory) return;

      // Make sure the query is at least 3 letters
      if (query.length < 4) return;

      const newSearchHistory = [query, ...searchHistory];
      setSearchHistory(newSearchHistory);
    },
    [searchHistory]
  );

  const value = {
    contexts,
    currentContext,
    setCurrentContext,
    query,
    setQuery,
    isMobileOverlayOpen,
    setIsMobileOverlayOpen,
    isDesktopOverlayOpen,
    setIsDesktopOverlayOpen,
    searchBarRightEdge,
    setSearchBarRightEdge,
    searchHistory,
    setSearchHistory,
    addToSearchHistory,
    indexName,
    setIndexName,
    lastAlgoliaQueryId,
    setLastAlgoliaQueryId,
    isMobileFilterOverlayOpen,
    setIsMobileFilterOverlayOpen,
  };
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = React.useContext(SearchContext);
  if (context === undefined) {
    throw new Error(
      "useSearchContext must be used within a SearchContextProvider"
    );
  }
  return context;
};
