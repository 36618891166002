import { motion } from "framer-motion";

const AnimatedCross = ({ isActive, size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="Icon"
  >
    <motion.line
      x1="18"
      y1="6"
      x2="6"
      y2="18"
      initial={{ rotate: 0, scaleX: 1 }}
      animate={{
        rotate: isActive ? 45 : 0,
        scaleX: isActive ? 0.75 : 1,
      }}
    />
    <motion.line
      x1="6"
      y1="6"
      x2="18"
      y2="18"
      initial={{ rotate: 0, scaleX: 1 }}
      animate={{
        rotate: isActive ? -45 : 0,
        scaleX: isActive ? 0.75 : 1,
      }}
    />
  </svg>
);

AnimatedCross.defaultProps = {
  isActive: false,
};

export default AnimatedCross;
