import AuthControl from "@/components/AuthControl";
import AuthMenu from "@/components/AuthMenu";
import GradientWrapper from "@/components/GradientWrapper";
import MenuToggle from "@/components/MenuToggle";
import MobLogotype from "@/components/MobLogotype";
import SearchBar from "@/components/SearchBar";
import SearchMobileFilterToggle from "@/components/SearchMobileFilterToggle";
import { useHeaderContext } from "@/contexts/HeaderContext";
import { useMenuContext } from "@/contexts/MenuContext";
import { NotificationsContext } from "@/contexts/NotificationsContext";
import { useSearchContext } from "@/contexts/SearchContext";
import {
  joinButtonClicked,
  logoClicked,
  searchBarFocused,
  searchBarQuerySubmitted,
} from "@/gtmEvents/globalHeader";
import useCurrentUser from "@/hooks/useCurrentUser";
import useIsHomepage from "@/hooks/useIsHomepage";
import pushToDataLayer from "@/lib/pushToDataLayer";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef, useState } from "react";

const Header = ({ displayCurrency, theme = "light" }) => {
  const { showNotification } = useContext(NotificationsContext);

  const { isPinned, isTop, showBorder } = useHeaderContext();

  const headerRef = useRef(null);

  const setHeaderHeight = () => {
    const headerHeight = headerRef.current.offsetHeight;
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerHeight}px`
    );
  };

  const router = useRouter();

  useEffect(() => {
    setHeaderHeight();
    window.addEventListener("resize", setHeaderHeight);

    // Run setHeaderHeight on route changes
    const handleRouteChange = () => {
      setHeaderHeight();
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      window.removeEventListener("resize", setHeaderHeight);
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <header
        ref={headerRef}
        className={clsx(
          "Header",
          "fixed left-0 right-0 top-0 z-50 w-screen transform-gpu lg:border-b print:static print:!bg-white print:text-zinc-800",
          {
            "bg-zinc-800 text-white": theme === "dark",
            "bg-white text-zinc-800": theme === "light",
            "lg:translate-y-0 print:!translate-y-0":
              (isTop || isPinned) && !showNotification,
            "translate-y-12 print:!translate-y-0": showNotification,
            "lg:border-b-transparent": !showBorder,
            "lg:border-b-zinc-200": showBorder,
          }
        )}
      >
        <div
          className={clsx(
            "Header__container",
            "relative z-20 h-16 lg:h-20 2xl:h-24"
          )}
        >
          <_Desktop currency={displayCurrency} theme={theme} />
          <_Mobile currency={displayCurrency} theme={theme} />
        </div>
      </header>
      <_MobileSearchBar theme={theme} />
    </>
  );
};

const getUpgradeUrl = (currency: string) =>
  `/premium${currency ? `?displayCurrency=${currency}` : ""}#sign-up`;

const _Desktop = observer(({ currency, theme = "dark" }) => {
  const { isLoggedOut, isLoggedIn, isFetching, currentUser } = useCurrentUser();
  const { isSearchVisible, setIsSearchVisible, isHeroScrolledPast } =
    useHeaderContext();

  const router = useRouter();
  const isHomePage = useIsHomepage();

  const { setIsDesktopOverlayOpen } = useSearchContext();

  const isMobPlus = currentUser?.plan === 2;

  const handleFocus = () => {
    setIsDesktopOverlayOpen(router.pathname !== "/search");

    // Send an event to GTM
    pushToDataLayer(searchBarFocused);
  };

  return (
    <div
      className={clsx(
        "hidden h-full grid-cols-12 items-center sm:gap-6 lg:grid"
      )}
    >
      <div
        className={clsx(
          "relative col-span-4 flex h-full items-center pl-12 print:!hidden"
        )}
      >
        <Link href="/">
          <a
            className={clsx("flex h-8 w-22 items-center active:scale-95")}
            aria-label="Mob"
            onClick={() => {
              pushToDataLayer(logoClicked);
            }}
          >
            <MobLogotype />
          </a>
        </Link>
        <div
          className={clsx(
            "ml-6 whitespace-nowrap border-l border-l-zinc-500 pl-6 font-spatial text-xl font-normal"
          )}
        >
          Love cooking
        </div>
      </div>
      <div
        className={clsx(
          "col-span-8 flex h-full items-center justify-end space-x-4 pr-12 print:hidden"
        )}
      >
        <AnimatePresence>
          {isHeroScrolledPast && (
            <motion.div
              className={clsx("flex-1 border-r pr-4", {
                "border-r-zinc-500": theme === "dark",
                "border-r-zinc-200": theme === "light",
              })}
              initial={{ opacity: 0, x: 0 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 0 }}
              transition={{ duration: 0.15, ease: "easeOut" }}
            >
              <_SearchBar
                handleFocus={handleFocus}
                inputId={"desktop-search-input"}
                theme={theme}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <div className={clsx("flex space-x-4")}>
          {(isFetching || isLoggedOut) && <AuthControl theme={theme} />}
          {isLoggedOut && (
            <_JoinCTA context="desktop" currency={currency} theme={theme} />
          )}
          {isLoggedIn && (
            <>
              <div className="flex gap-2">
                <div>
                  <AuthMenu />
                </div>
                <div>
                  {!isMobPlus && (
                    <Link href={getUpgradeUrl(currency)}>
                      <a>
                        <GradientWrapper
                          theme="purple"
                          className={clsx("flex items-center")}
                        >
                          <div className={clsx("h-full p-[2px]")}>
                            <div
                              className={clsx(
                                "relative z-10 flex h-full items-center space-x-2 rounded-full px-4 text-zinc-50"
                              )}
                            >
                              <div
                                className={clsx(
                                  "whitespace-nowrap font-body text-sm"
                                )}
                              >
                                Upgrade
                              </div>
                            </div>
                          </div>
                        </GradientWrapper>
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            </>
          )}
          <div className={clsx("Header__menuToggle")}>
            <MenuToggle />
          </div>
        </div>
      </div>
    </div>
  );
});

const _Mobile = observer(({ currency, theme }) => {
  const { isLoggedOut, isLoggedIn, currentUser } = useCurrentUser();
  const { isHeroScrolledPast, setIsSearchVisible } = useHeaderContext();

  const router = useRouter();

  const isMobPlus = currentUser?.plan === 2;

  return (
    <div
      className={clsx("flex h-full flex-wrap lg:hidden", {
        "": theme === "light",
      })}
    >
      <div className={clsx("flex h-16 w-1/3 items-center justify-start px-6")}>
        <Link href="/">
          <a
            className={clsx("flex h-auto w-24 items-center active:scale-95")}
            aria-label="Mob"
          >
            <MobLogotype />
          </a>
        </Link>
      </div>

      <div className={clsx("flex h-16 w-2/3 items-center justify-end px-6")}>
        {isLoggedOut && (
          <div className={clsx("flex space-x-2")}>
            <_JoinCTA context="mobile" currency={currency} />
          </div>
        )}

        {isLoggedIn && (
          <>
            <div className="flex gap-2">
              <div>
                <AuthMenu />
              </div>
              {!isMobPlus && (
                <div>
                  <Link href={getUpgradeUrl(currency)}>
                    <a>
                      <GradientWrapper
                        theme="purple"
                        className={clsx("flex items-center")}
                      >
                        <div className={clsx("h-full p-[2px]")}>
                          <div
                            className={clsx(
                              "relative z-10 flex h-full items-center space-x-2 rounded-full px-4 text-zinc-50"
                            )}
                          >
                            <div
                              className={clsx(
                                "whitespace-nowrap font-body text-sm"
                              )}
                            >
                              Upgrade
                            </div>
                          </div>
                        </div>
                      </GradientWrapper>
                    </a>
                  </Link>
                </div>
              )}
            </div>
          </>
        )}

        <div>
          <MenuToggle />
        </div>
      </div>
    </div>
  );
});

const _MobileSearchBar = observer(({ theme = "dark" }) => {
  const { setIsMobileOverlayOpen } = useSearchContext();

  const handleFocus = () => {
    // If we're at /search, don't open the overlay
    if (isSearchIndex) return;

    setIsMobileOverlayOpen(!isSearchIndex);

    // Send an event to GTM
    pushToDataLayer(searchBarFocused);
  };

  const { isHeroScrolledPast } = useHeaderContext();

  const router = useRouter();

  // Check if we're on the search page
  const isSearchIndex = router.pathname === "/search";

  if (!isHeroScrolledPast) {
    return null;
  }

  return (
    <div
      className={clsx(
        "fixed inset-x-0 top-0 z-40 flex h-30 w-full items-start bg-white pt-16 lg:hidden"
      )}
    >
      <div
        className={clsx("flex-1", {
          "pl-6 pr-4": isSearchIndex,
          "px-6": !isSearchIndex,
        })}
      >
        <_SearchBar
          handleFocus={handleFocus}
          inputId={"mobile-search-input"}
          theme={theme}
        />
      </div>
      {isSearchIndex && (
        <div
          className={clsx("border-l pl-4 pr-6", {
            "border-l-zinc-700": theme === "dark",
            "border-l-zinc-200": theme === "light",
          })}
        >
          <SearchMobileFilterToggle />
        </div>
      )}
    </div>
  );
});

const _SearchBar = observer(({ handleFocus, inputId, theme = "dark" }) => {
  const router = useRouter();

  const { isLoggedIn, currentUser } = useCurrentUser();

  // Live search is available for logged in users, on the search page
  const searchAsYouType =
    router.pathname !== "/search" && isLoggedIn && currentUser.plan === 2;

  return (
    <SearchBar
      className={clsx("min-w-3xl")}
      searchAsYouType={searchAsYouType}
      onFocus={handleFocus}
      inputId={inputId}
      onSubmit={(query) => pushToDataLayer(searchBarQuerySubmitted, { query })}
      theme={theme}
      inputClassName={clsx("bg-zinc-100")}
    />
  );
});

const _JoinCTA = ({ context = "desktop", currency, theme = "dark" }) => {
  return (
    <Link
      href={`/signup${currency.length ? `?displayCurrency=${currency}` : ""}`}
    >
      <a
        className={clsx("Header__joinCta", "h-component")}
        onClick={() => pushToDataLayer(joinButtonClicked)}
      >
        <div
          className={clsx(
            "relative z-10 flex h-full items-center whitespace-nowrap rounded-full px-4 font-body text-sm font-medium transition ease-out",
            "bg-acid text-zinc-800 hover:bg-zinc-50 hover:text-zinc-950"
          )}
        >
          Join the mob
        </div>
      </a>
    </Link>
  );
};

export default Header;
