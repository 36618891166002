import SearchOverlayDesktop from "@/components/SearchOverlayDesktop";
import SearchOverlayMobile from "@/components/SearchOverlayMobile";
import { useScreenSizeContext } from "@/contexts/ScreenSizeContext";

const SearchOverlay = ({ theme }) => {
  const { isBreakpoint, breakpoint } = useScreenSizeContext();

  if (!breakpoint) return null;

  if (isBreakpoint("lg")) {
    return <SearchOverlayDesktop theme={theme} />;
  } else {
    return <SearchOverlayMobile theme={theme} />;
  }
};

export default SearchOverlay;
