export function getAlgoliaFacetFilters(rail): string[] | string[][] {
  const groupedFacetFilters: { [key: string]: string[] } = {};

  for (const key in rail) {
    if (
      Array.isArray(rail[key]) &&
      (key.startsWith("include") || key.startsWith("exclude")) &&
      !key.endsWith("Recipes") // don't include includeRecipes or excludeRecipes here
    ) {
      rail[key].forEach((item) => {
        const filter = key.startsWith("exclude")
          ? `${item.groupHandle}.title:-${item.title}`
          : `${item.groupHandle}.title:${item.title}`;

        if (!groupedFacetFilters[item.groupHandle]) {
          groupedFacetFilters[item.groupHandle] = [];
        }

        groupedFacetFilters[item.groupHandle].push(filter);
      });
    }
  }

  // Convert grouped filters into an array of arrays
  const facetFilters = Object.values(groupedFacetFilters);

  // Finally, if a section has been selected, add that to the facet filters
  if (rail.sectionToShow) {
    facetFilters.push([`section.handle:${rail.sectionToShow}`]);
  }

  return facetFilters;
}

export default getAlgoliaFacetFilters;
