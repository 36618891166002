import axios from "axios";
import { makeAutoObservable } from "mobx";
import { stringify } from "qs";

export class APIStore {
  constructor() {
    makeAutoObservable(this);
  }

  csrfTokenName = null;

  setCsrfTokenName = (value) => {
    this.csrfTokenName = value;
  };

  csrfTokenValue = null;

  setCsrfTokenValue = (value) => {
    this.csrfTokenValue = value;
  };

  get isReady() {
    return !!this.csrfTokenValue;
  }

  fetchCsrfToken = () => {
    const csrfResponse = this.axiosInstance({
      method: "GET",
      url: "/actions/site-module/site/get-csrf",
    })
      .then((response) => {
        if (response.status === 200 && response.data) {
          this.setCsrfTokenName(response.data.name);
          this.setCsrfTokenValue(response.data.value);
        } else {
          return Promise.reject(response.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});

    return csrfResponse;
  };

  get axiosInstance() {
    const client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_CMS_URL,
      crossDomain: true,
      withCredentials: true,
      maxRedirects: 0,
      transformRequest: [
        (data) =>
          stringify({
            [this.csrfTokenName]: this.csrfTokenValue,
            ...data,
          }),
      ],
    });

    return client;
  }
}
