// TODO: change this to ListsContext when we've fully migrated, for clarity

import { ReactNode, createContext, useContext, useState } from "react";

type CollectionsContextType = {
  isOverlayOpen: boolean;
  setIsOverlayOpen: (isOpen: boolean) => void;
  isOverlayHidden: boolean;
  setIsOverlayHidden: (isHidden: boolean) => void;
};

// Provide initial context value
const initialContextValue: CollectionsContextType = {
  isOverlayOpen: true,
  setIsOverlayOpen: () => null,
  isOverlayHidden: false,
  setIsOverlayHidden: () => null,
};

// Creating new context
export const CollectionsContext =
  createContext<CollectionsContextType>(initialContextValue);

type CollectionsProviderProps = {
  children: ReactNode;
};

// Provider component
export const CollectionsContextProvider = ({
  children,
}: CollectionsProviderProps) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const [isOverlayHidden, setIsOverlayHidden] = useState<boolean>(false);

  return (
    <CollectionsContext.Provider
      value={{
        isOverlayOpen,
        setIsOverlayOpen,
        isOverlayHidden,
        setIsOverlayHidden,
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};

export const useCollectionsContext = (): CollectionsContextType => {
  const context = useContext(CollectionsContext);

  if (context === undefined) {
    throw new Error(
      "useCollectionsContext must be used within a CollectionsProvider"
    );
  }

  return context;
};
