import clsx from "clsx";
import { useRefinementList } from "react-instantsearch";
import DraggableScrollArea from "./DraggableScrollArea";

const SearchOverlayRefinements = ({ isMobile = false, theme = "dark" }) => {
  return (
    <div className={clsx("SearchOverlayRefinements", "relative")}>
      <DraggableScrollArea>
        <div
          className={clsx("flex", {
            "pr-9": !isMobile,
            "pr-4": isMobile,
          })}
        >
          <_RefinementList
            attribute="dietaryRequirements.title"
            sortBy={["count:desc"]}
            theme={theme}
          />
          <_RefinementList
            attribute="recipeCategories.title"
            sortBy={["count:desc"]}
            theme={theme}
          />
        </div>
      </DraggableScrollArea>
      <div
        className={clsx(
          "pointer-events-none absolute inset-y-0 right-0 z-10 h-full bg-gradient-to-r from-transparent ",
          {
            "w-12": !isMobile,
            "w-8": isMobile,
            "to-zinc-950": theme === "dark",
          }
        )}
      ></div>
    </div>
  );
};

const _RefinementList = (props) => {
  const { theme } = props;
  const { items, refine } = useRefinementList(props);

  return (
    <div
      className={clsx("SearchOverlayRefinements__refinementList", {
        "text-zinc-50": theme === "dark",
      })}
    >
      <ul className={clsx("", "flex")}>
        {items.map((item) => {
          return (
            <li
              key={item.label}
              className={clsx("group", {
                selected: item.isRefined,
              })}
            >
              <label
                className={clsx(
                  "h-component mr-2 flex cursor-pointer items-center rounded-full border px-4 font-body text-sm focus:border-current",
                  {
                    "border-zinc-700 text-zinc-50 group-hover:bg-zinc-50 group-hover:text-zinc-950 group-[.selected]:bg-zinc-50 group-[.selected]:text-zinc-950":
                      theme === "dark",
                    "border-zinc-200 text-zinc-950 group-hover:border-zinc-950 group-hover:bg-zinc-950 group-hover:text-zinc-50 group-[.selected]:bg-zinc-950 group-[.selected]:text-zinc-50":
                      theme === "light",
                  }
                )}
              >
                <input
                  type="checkbox"
                  checked={item.isRefined}
                  onChange={() => refine(item.value)}
                  className={clsx("hidden")}
                />
                <span className={clsx("whitespace-nowrap")}>{item.label}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchOverlayRefinements;
