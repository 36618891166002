export const BASE_ALGOLIA_INDEX =
  process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME;

// Premium user indices
export const INDEX_POPULARITY_DESC = `${BASE_ALGOLIA_INDEX}_popularity_desc`;
export const INDEX_LATEST_DESC = `${BASE_ALGOLIA_INDEX}_latest_desc`;
export const INDEX_LATEST_DESC_ABSOLUTE = `${BASE_ALGOLIA_INDEX}_latest_desc_absolute`;

// Free user indices
export const INDEX_POPULARITY_DESC_FREE = `${BASE_ALGOLIA_INDEX}_popularity_desc_free`;
export const INDEX_LATEST_DESC_FREE = `${BASE_ALGOLIA_INDEX}_latest_desc_free`;
export const INDEX_LATEST_DESC_ABSOLUTE_FREE = `${BASE_ALGOLIA_INDEX}_latest_desc_absolute_free`;

// Query Suggestions index
export const SUGGESTIONS_INDEX = `${BASE_ALGOLIA_INDEX}_query_suggestions`;

// Utility function to get index name based on user plan
export const getIndexNameForUserPlan = (userPlan, sortBy) => {
  switch (sortBy) {
    case "popularity":
      return userPlan === 2
        ? INDEX_POPULARITY_DESC
        : INDEX_POPULARITY_DESC_FREE;
    case "latest":
      return userPlan === 2 ? INDEX_LATEST_DESC : INDEX_LATEST_DESC_FREE;
    case "absolute":
      return userPlan === 2
        ? INDEX_LATEST_DESC_ABSOLUTE
        : INDEX_LATEST_DESC_ABSOLUTE_FREE;
    case "none":
    default:
      return BASE_ALGOLIA_INDEX; // Return the base index for "No sort"
  }
};

// Used by the SearchSortBy component.
// We include all of them and then show/hide based on the user's plan.
// It is a constant here because it's needed by useSortBy in both ConfigureSearch and SearchSortBy.
export const sortByItems = [
  {
    label: "Popular",
    value: INDEX_POPULARITY_DESC,
  },
  {
    label: "Latest",
    value: INDEX_LATEST_DESC,
  },
];
