import useHashtagLabs from "@/src/ads/hooks/useHashtagLabs";
import React, { createContext, ReactNode, useContext } from "react";

type HashtagLabsContextType = {
  setPageTargeting: (targeting: Record<string, string>) => void;
};

const HashtagLabsContext = createContext<HashtagLabsContextType | undefined>(
  undefined
);

export const HashtagLabsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { setPageTargeting } = useHashtagLabs();

  return (
    <HashtagLabsContext.Provider value={{ setPageTargeting }}>
      {children}
    </HashtagLabsContext.Provider>
  );
};

export const useHashtagLabsContext = () => {
  const context = useContext(HashtagLabsContext);
  if (context === undefined) {
    throw new Error(
      "useHashtagLabsContext must be used within a HashtagLabsProvider"
    );
  }
  return context;
};
