import { useRouter } from "next/router";

const useIsHomepage = () => {
  const router = useRouter();

  const isHomepage = router.pathname === "/";

  return isHomepage;
};

export default useIsHomepage;
