import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import ConfigureSearch from "@/components/ConfigureSearch";
import Layout from "@/components/Layout";
import { INDEX_LATEST_DESC_FREE } from "@/constants/algoliaConstants";
import { AdContextProvider } from "@/contexts/AdContext";
import { CollectionsContextProvider } from "@/contexts/CollectionsContext";
import GeoPreferenceContextProvider from "@/contexts/GeoPreferenceContext";
import { HeaderContextProvider } from "@/contexts/HeaderContext";
import { MenuContextProvider } from "@/contexts/MenuContext";
import { NavigationContextProvider } from "@/contexts/NavigationContext";
import { NotificationsContextProvider } from "@/contexts/NotificationsContext";
import { PaywallContextProvider } from "@/contexts/PaywallContext";
import { PlannerContextProvider } from "@/contexts/PlannerContext";
import { ScreenSizeContextProvider } from "@/contexts/ScreenSizeContext";
import { SearchContextProvider } from "@/contexts/SearchContext";
import { SubscriptionContextProvider } from "@/contexts/SubscriptionContext";
import SessionManager from "@/components/SessionManager";
import { SessionProvider } from "@/contexts/SessionContext";
import searchClient from "@/lib/algoliaSearchClient";
import DidomiConsentManager from "@/src/components/DidomiConsentManager";
import JsonLdSchema from "@/src/components/JsonLdSchema";
import { HashtagLabsProvider } from "@/src/contexts/HashtagLabsContext";
import { HomeHeroContextProvider } from "@/src/contexts/HomeHeroContext";
import useFathom from "@/src/hooks/useFathom";
import useIntercom from "@/src/hooks/useIntercom";
import useSetPreviousUrl from "@/src/hooks/useSetPreviousUrl";
import "@/styles/globals.css";
import "@/styles/index.scss";
import * as Tooltip from "@radix-ui/react-tooltip";
import { observer } from "mobx-react";
import Head from "next/head";
import Script from "next/script";
import { InstantSearch } from "react-instantsearch";
import { Seomatic } from "react-seomatic";
import { SWRConfig } from "swr";
import { YbugProvider } from "ybug-react";

function MyApp({ Component, pageProps }) {
  const {
    navigation = {},
    seomatic = {},
    jsonLdSchema = undefined,
  } = pageProps;

  /*
   * Bootstrap various third party services
   */

  // Intercom
  useIntercom();

  // Fathom
  useFathom();

  /*
   * Init internal hooks
   */

  // Save the previous URL to aid with redirecting after logins
  useSetPreviousUrl();

  // Use the layout defined by the page, or default to the standard layout
  const getLayout =
    Component.getLayout ||
    ((page) => <Layout navigation={navigation}>{page}</Layout>);

  useEffect(() => {
    if (typeof window !== "undefined") {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host:
          process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
      });
    }
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <SWRConfig
        value={{
          fetcher: (url) => fetch(url).then((r) => r.json()),
        }}
      >
        <Seomatic Head={Head} {...seomatic} />

        {!!jsonLdSchema && <JsonLdSchema schema={jsonLdSchema} />}

        <Head>
          <meta
            name="viewport"
            content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"
          ></meta>
        </Head>

        <DidomiConsentManager />

        <Script
          strategy="lazyOnload"
          src={
            process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
              ? "https://htlbid.com/v3/mob.co.uk/htlbid.js"
              : "https://htlbid.com/stage/v3/mob.co.uk/htlbid.js"
          }
        />

        <YbugProvider
          ybugId="4tdtt7rpsysnnq3r3cm8"
          settings={{
            hide_launcher: true,
          }}
        >
          <Tooltip.Provider delayDuration={0}>
            <InstantSearch
              searchClient={searchClient}
              future={{
                preserveSharedStateOnUnmount: true,
              }}
              indexName={INDEX_LATEST_DESC_FREE}
              insights={true}
            >
              <HomeHeroContextProvider>
                <HashtagLabsProvider>
                  <MenuContextProvider>
                    <CollectionsContextProvider>
                      <GeoPreferenceContextProvider>
                        <PlannerContextProvider>
                          <SubscriptionContextProvider>
                            <NotificationsContextProvider>
                              <SearchContextProvider>
                                <HeaderContextProvider>
                                  <NavigationContextProvider>
                                    <PaywallContextProvider>
                                      <ScreenSizeContextProvider>
                                        <AdContextProvider>
                                          <SessionProvider>
                                            <ConfigureSearch />
                                            <SessionManager />
                                            {getLayout(
                                              <Component {...pageProps} />
                                            )}
                                          </SessionProvider>
                                        </AdContextProvider>
                                      </ScreenSizeContextProvider>
                                    </PaywallContextProvider>
                                  </NavigationContextProvider>
                                </HeaderContextProvider>
                              </SearchContextProvider>
                            </NotificationsContextProvider>
                          </SubscriptionContextProvider>
                        </PlannerContextProvider>
                      </GeoPreferenceContextProvider>
                    </CollectionsContextProvider>
                  </MenuContextProvider>
                </HashtagLabsProvider>
              </HomeHeroContextProvider>
            </InstantSearch>
          </Tooltip.Provider>
        </YbugProvider>
      </SWRConfig>
    </PostHogProvider>
  );
}

export default observer(MyApp);
