// apiClient.js
const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL;

let authToken: string | null = null;
let authTokenPromise: Promise<string> | null = null;
let resolveAuthToken: ((token: string) => void) | null = null;

const apiClient = {
  // Sets the authentication token and resolves any pending promises
  // This is called by AuthStore when a user is logged in/a user is fetched
  setAuthToken: (token: string) => {
    authToken = token;
    if (resolveAuthToken) {
      resolveAuthToken(token);
      authTokenPromise = null;
      resolveAuthToken = null;
    }
  },

  // Clears the authentication token and any pending promises
  clearAuthToken: () => {
    authToken = null;
    authTokenPromise = null;
    resolveAuthToken = null;
  },

  // Waits for the authentication token to be set, returns a promise.
  // We do this because without a token, the request will simply fail.
  waitForAuthToken: (): Promise<string> => {
    if (authToken) {
      return Promise.resolve(authToken);
    }
    if (!authTokenPromise) {
      authTokenPromise = new Promise((resolve) => {
        resolveAuthToken = resolve;
      });
    }
    return authTokenPromise;
  },

  // This is the base fetch function that all the other specific get/post/put/delete functions call
  // Automatically includes the auth token if available
  fetch: async (
    endpoint: string,
    options: RequestInit = {},
    userUid?: string,
    requireAuth = true
  ) => {
    const url = `${API_BASE_URL}${endpoint}`;

    try {
      const headers = new Headers(options.headers);
      headers.set("Content-Type", "application/json");

      // Some requests don't need auth, but most do
      if (requireAuth) {
        if (!authToken) {
          await apiClient.waitForAuthToken();
        }
        headers.set("Authorization", `Bearer ${authToken}`);
      }

      const response = await fetch(url, {
        ...options,
        headers,
      });

      const data = await response.json();

      if (!data.success) {
        // If the API returns success: false, throw an error with the message
        throw new Error(data.error || "An error occurred");
      }

      return {
        ...data,
        userUid,
      };
    } catch (error) {
      console.error(error);
      // Return the error message
      return {
        success: false,
        message:
          error instanceof Error
            ? error.message
            : "An unexpected error occurred",
        userUid,
      };
    }
  },

  // Performs a GET request to the API, optionally includes user UID in headers
  get: (
    endpoint: string,
    userUid?: string,
    queryParams: Record<string, string> = {},
    requireAuth = true
  ) => {
    let url = endpoint;
    const searchParams = new URLSearchParams(queryParams);
    const queryString = searchParams.toString();
    if (queryString) {
      url += `?${queryString}`;
    }
    return apiClient.fetch(url, {}, userUid, requireAuth);
  },

  // Performs a POST request to the API, includes user UID in headers and data in body
  post: (endpoint, userUid, data) => {
    return apiClient.fetch(
      endpoint,
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      userUid
    );
  },

  // Performs a PUT request to the API, includes user UID in headers and data in body
  put: (endpoint, userUid, data) =>
    apiClient.fetch(
      endpoint,
      {
        method: "PUT",
        body: JSON.stringify(data),
      },
      userUid
    ),

  // Performs a PATCH request to the API, includes user UID in headers and data in body
  patch: (endpoint, userUid, data) =>
    apiClient.fetch(
      endpoint,
      {
        method: "PATCH",
        body: JSON.stringify(data),
      },
      userUid
    ),

  // Performs a DELETE request to the API, includes user UID in headers
  delete: (endpoint, userUid) =>
    apiClient.fetch(
      endpoint,
      {
        method: "DELETE",
      },
      userUid
    ),
};

export default apiClient;
