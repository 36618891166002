import { createAuthStoreWrapper } from "@/stores/createAuthStoreWrapper";
import React from "react";
import { APIStore } from "stores/APIStore";
import { AuthStore } from "stores/AuthStore";
import { CompetitionStore } from "stores/CompetitionStore";
import { MenuStore } from "stores/MenuStore";
import { ProfileStore } from "stores/ProfileStore";
import { TimerStore } from "stores/TimerStore";
import { UIStore } from "stores/UIStore";

// Init the api store first, it needs to be fed into all other stores
const apiStore = new APIStore();

// Create the AuthStore instance. Refer to createAuthStoreWrapper for more details
const authStore = createAuthStoreWrapper(new AuthStore({ apiStore }));

const competitionStore = new CompetitionStore({ apiStore });
const menuStore = new MenuStore({ apiStore });
const profileStore = new ProfileStore({ apiStore });
const timerStore = new TimerStore({ apiStore });
const uiStore = new UIStore({ apiStore });

const stores = {
  apiStore,
  authStore,
  competitionStore,
  menuStore,
  profileStore,
  timerStore,
  uiStore,
};

export const storesContext = React.createContext(stores);
export default storesContext;
