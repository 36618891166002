import { find } from "lodash";
import { makeAutoObservable } from "mobx";
import Timer from "models/Timer";

export class TimerStore {
  constructor({ apiStore }) {
    makeAutoObservable(this);

    this.apiStore = apiStore;
  }

  timers = [];

  addTimer = (recipeId, stepId, name, minutes, isActive) => {
    const timer = new Timer(this, {
      recipeId,
      stepId,
      name,
      minutes,
      isActive,
    });

    this.timers.push(timer);
  };

  getStepTimer = (recipeId, stepId) => find(this.timers, { recipeId, stepId });

  get haveAnyTimersFinished() {
    return this.timers.filter((t) => t.isFinished).length > 0;
  }

  get count() {
    return this.timers.length;
  }
}

export default TimerStore;
