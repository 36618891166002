import { useState, useEffect } from "react";

const usePermission = (vendorId) => {
  const [permission, setPermission] = useState(false);

  // When we have didomi permission for vendorId update permission to true
  const onPermission = () => {
    setPermission(true);
  };

  // When we don't have didomi permission for vendorId update permission to false
  const onNoPermission = () => {
    setPermission(false);
  };

  useEffect(() => {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(function (Didomi) {
      if (Didomi.isConsentRequired()) {
        Didomi.getObservableOnUserConsentStatusForVendor(vendorId)
          .filter(function (status) {
            return status === true;
          }) // Filter out updates where status is not true
          .first() // Only get the first consent status update
          .subscribe(function () {
            onPermission();
          });
      } else {
        onPermission();
      }
    });

    window.didomiOnReady.push(function (Didomi) {
      if (Didomi.isConsentRequired()) {
        Didomi.getObservableOnUserConsentStatusForVendor(vendorId).subscribe(
          function (consentStatusForVendor) {
            if (consentStatusForVendor === true) {
              onPermission();
            } else if (consentStatusForVendor === false) {
              onNoPermission();
            } else if (consentStatusForVendor === undefined) {
              onNoPermission();
            }
          }
        );
      } else {
        onPermission();
      }
    });

    // Cleanup
    return () => {
      window.didomiOnReady = [];
    };
  }, [vendorId]);

  return permission;
};

export default usePermission;
