import GTMEvent from "@/types/GTMEvent";

/* Main Menu events */
const category = "mainMenu";

export const toggleButtonClicked = {
  category,
  name: "toggleButton",
  action: "clicked",
  label: "User clicked main menu toggle button",
} as GTMEvent;

export const quickSearchItemClicked = {
  category,
  name: "quickSearchItem",
  action: "clicked",
  label: "User clicked quick search item",
} as GTMEvent;

export const chefsItemClicked = {
  category,
  name: "chefsItem",
  action: "clicked",
  label: "User clicked chefs item",
} as GTMEvent;

export const recipesItemClicked = {
  category,
  name: "recipesItem",
  action: "clicked",
  label: "User clicked recipes item",
} as GTMEvent;

export const collectionsItemClicked = {
  category,
  name: "collectionsItem",
  action: "clicked",
  label: "User clicked collections item",
} as GTMEvent;

export const mealPlansItemClicked = {
  category,
  name: "mealPlansItem",
  action: "clicked",
  label: "User clicked meal plans item",
} as GTMEvent;

export const loginItemClicked = {
  category,
  name: "loginItem",
  action: "clicked",
  label: "User clicked login item",
} as GTMEvent;

const mainMenu = {
  toggleButtonClicked,
  quickSearchItemClicked,
  chefsItemClicked,
  recipesItemClicked,
  collectionsItemClicked,
  mealPlansItemClicked,
  loginItemClicked,
};

export default mainMenu;
