import { useEffect, useState } from "react";

const useApp = (): boolean => {
  const [isMobApp, setIsMobApp] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsMobApp(userAgent.startsWith("Mob"));
  }, []);

  return isMobApp;
};

export default useApp;
