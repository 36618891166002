import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import Link from "next/link";

const MobPlusIndicator = ({
  locked = true,
  size = "base",
  theme = "dark",
  border = false,
}) => {
  return (
    <>
      {locked && (
        <Link href="/premium">
          <a
            className={clsx(
              "MobPlusIndicator",
              "flex items-center justify-center rounded-full ",
              {
                "bg-zinc-50 text-zinc-700": theme === "dark",
                "bg-zinc-900 text-zinc-50": theme === "light",
                border: border,
                "border-zinc-700": border && theme === "dark",
                "border-zinc-50": border && theme === "light",
                "w-component h-component": size === "base",
                "h-7 w-7": size === "sm",
              }
            )}
            title="A Mob member exclusive"
          >
            <LockClosedIcon
              className={clsx({
                "h-4 w-4": size === "base",
                "h-3 w-3": size === "sm",
              })}
              aria-hidden="true"
            />
          </a>
        </Link>
      )}

      {/* {!locked && (
        <div
          className={clsx(
            "MobPlusIndicator",
            "flex items-center justify-center rounded-full",
            {
              "bg-zinc-950 text-zinc-50": theme === "dark",
              "bg-zinc-50 text-zinc-900": theme === "light",
              border: border,
              "border-zinc-700": border && theme === "light",
              "border-zinc-50": border && theme === "dark",
              "w-component h-component": size === "base",
              "h-7 w-7": size === "sm",
            }
          )}
          title="A Mob member exclusive"
        >
          <LockOpenIcon
            className={clsx({
              "h-4 w-4": size === "base",
              "h-3 w-3": size === "sm",
            })}
            aria-hidden="true"
          />
        </div>
      )} */}
    </>
  );
};

export default MobPlusIndicator;
