import React from "react";

type JsonLdSchema = Record<string, unknown>;

type JsonLdSchemaProps = {
  schema: JsonLdSchema | JsonLdSchema[];
};

const JsonLdSchema: React.FC<JsonLdSchemaProps> = ({ schema }) => {
  const schemaContent = Array.isArray(schema)
    ? {
        "@context": "https://schema.org",
        "@graph": schema,
      }
    : schema;

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaContent) }}
    />
  );
};

export default JsonLdSchema;
